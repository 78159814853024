/**
 * Global app/site styles
 * =================================
 */

:root {
  --color-primary: #{$color-primary};
  --color-secondary: #{$color-secondary};
  --color-third: #{$color-third};
  --color-fourth: #{$color-fourth};
  --color-fifth: #{$color-fifth};

  --color-front: #{$color-front};

  --color-front-lighter: #{lighten($color-front, 30%)};
  --color-front-darker: #{darken($color-front, 50%)};
  --color-front-lightest: #{lighten($color-front, 50%)};
  --color-secondary-front: #{$color-secondary-front};

  --color-back: #{$color-back};
  --color-secondary-back: #{$color-secondary-back};

  --color-back-lighter: #{lighten($color-back, 30%)};
  --color-back-lightest: #{lighten($color-back, 20%)};

  --color-rarity-common:#{$color-rarity-common};
  --color-rarity-uncommon:#{$color-rarity-uncommon};
  --color-rarity-rare:#{$color-rarity-rare};
  --color-rarity-epic:#{$color-rarity-epic};
  --color-rarity-legend:#{$color-rarity-legend};


--special-color:linear-gradient(
  45deg,
  darken($color-fourth, 11),
  darken($color-secondary, 1)
)
10% round;
  --heading-color: #292922;
  --account-center-position-right: 100px;
  --account-center-position-top: 6px;
  --account-center-z-index: 999;
  --aug-inlay-bg: var(--color-back-lighter);
  --aug-border-all: 2px;


}

// [data-theme="dark"] {
//   --color-primary: #{$color-primary};
//   --secondary-color: #818cab;
//   --color-front: #{$color-back};
//   --color-front-lighter: #{lighten($color-back, 30%)};
//   --color-front-darker: #{darken($color-front, 50%)};
//   --color-secondary-front: #{$color-secondary-back};

//   --color-front-lightest: #{lighten($color-back, 50%)};

//   --color-back: #{$color-front};
//   --color-back-lighter: #{lighten($color-front, 10%)};
//   --color-back-lightest: #{lighten($color-front, 20%)};
//   --color-secondary-back: #{$color-secondary-front};

//   --heading-color: #818cab;
// }
html,
body {
  margin: 0;
  overflow: hidden;
  display: block;
  scroll-behavior: smooth !important;
  overflow-y: initial !important;
  background-color: var(--color-secondary-back);
  color: var(--color-front);
  position: relative;

}

/**
 * Default layout container
 */

.container {
  max-width: $page-content-max-width;
  margin: auto;
  @include media("<huge"){

    max-width:90%
  }
  // @include media("<mid"){

  //   max-width:80%
  // }
}


.flexLayout {
  display: flex;
  align-content: space-between;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media("<mid") {
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
}
.textCenter {
  text-align: center;
}
.pointer{
  cursor:pointer;
}

.enalargedBg {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  justify-content: center;

  display: flex;
  align-items: center;
  width: 100vw;
  background: rgba(255, 25, 255, 0.3);
  z-index: 999;
}

@-webkit-keyframes rainbow {
  0% {color: $red;}
  10% {color: $orange;}
  20% {color: $yellow;;}
  30% {color: #bcff79;}
  40% {color: $green;}
  50% {color: #77fab9;}
  60% {color: #65f8f8;}
  70% {color: #6bb5ff;}
  80% {color: $blue;}
  90% {color: $purple;}
  100% {color: #fd429f;}
  }
  @-ms-keyframes rainbow {
  0% {color: $red;}
  10% {color: $orange;}
  20% {color: $yellow;;}
  30% {color: #bcff79;}
  40% {color: $green;}
  50% {color: #77fab9;}
  60% {color: #65f8f8;}
  70% {color: #6bb5ff;}
  80% {color: $blue;}
  90% {color: $purple;}
  100% {color: #fd429f;}
  }
  @-o-keyframes rainbow {
  0% {color: $red;}
  10% {color: $orange;}
  20% {color: $yellow;;}
  30% {color: #bcff79;}
  40% {color: $green;}
  50% {color: #77fab9;}
  60% {color: #65f8f8;}
  70% {color: #6bb5ff;}
  80% {color: $blue;}
  90% {color: $purple;}
  100% {color: #fd429f;}
  }
  @keyframes rainbow {
  0% {color: $red;}
  10% {color: $orange;}
  20% {color: $yellow;;}
  30% {color: #bcff79;}
  40% {color: $green;}
  50% {color: #77fab9;}
  60% {color: #65f8f8;}
  70% {color: #6bb5ff;}
  80% {color: $blue;}
  90% {color: $purple;}
  100% {color: #fd429f;}
  }

