/**
 * Variables
 * =================================
 * - Typography
 * - Breakpoints
 * - Paths
 * - Layout & Grid
 * - App-specific
 */

/* stylelint-disable indentation */

/**
 * Typography
 * =================================
 *
 * Base sizes:
 * Set this in pixels (but do not add px),
 * the font-size mixin will convert to REMS
 */

$leading-base: 1;

$font-size-base: 1.4;
$line-height-base: 2;

$font-size-subheading: 1.8;
$line-height-subheading: 2.5;
$font-size-heading: 2.1;
$line-height-heading: 4;
$font-size-special: 3.1;
$line-height-special: 5;

$baseline: $line-height-base * 1px;

$line-height-special: round($font-size-special * $leading-base);
$specialline: $line-height-special * 1px;

$type-scale: 1.25; // Major third

$type: (
  micro: ko-ms(-2),
  small: ko-ms(-1),
  // h5, h6
  base: ko-ms(0),
  // p, h4
  mid: ko-ms(1),
  // h3
  large: ko-ms(2),
  // h2
  jumbo: ko-ms(3) // h1 ,,,
);

// Useful to set here when bold webfonts come as 400 font-weight.
$font-weight-bold: bold;

// Font stacks
$font-family-system: "Montserrat Alternates", system-ui, -apple-system,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-sans: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-mono: Menlo, Monaco, "Courier New", monospace;

$font-family-base: $font-family-system;
$font-family-alt: $font-family-serif;

$font-family-headings: "Indie Flower", system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; // using inherit will use $font-family-base for all headings
$font-weight-headings: bold; // instead of browser default, bold

/**
  * Breakpoints
  * =================================
  * There are no common breakpoints so these are just a suggestions
  * You'll need to define your own breakpoints to suit your design
  * Add your breakpoints to this Sass map
  * Used in the Kickoff grid
  */


// Setup layout max-width
$layout-margin: 0; // [in pixels] Space outside the grid
$layout-max-width: 1000; // [in pixels]
$layout-min-width: 960; // [in pixels] Minimum width for layout in <IE9
$layout-max-width--huge: 1100; // [in pixels]
$layout-min-width--huge: 1100; // [in pixels] Minimum width for layout in <IE9

/**
  * Grid
  * =================================
  * These variables will control the grid module.
  *
  * if your project doesnt use Kickoff’s default grid, you can delete these
  */

$grid-column-count: 12; // Total column count
$grid-gutter-width: 20; // [in pixels]

//Settings here override & affect the grid classes output
$responsive-grid-sizes: true; // Disable this if you don't want grid sizes for each breakpoint
$use-legacy-grid: true; // Disable this is you on’t want to output .no-flexbox classes for older browsers to use

/**
  * Path variables
  * =================================
  */
$img-path: "/assets/dist/img"; // Usage: background: url('#{$img-path}/img.jpg') 0 0 no-repeat;

/**
  * Z-index variables
  * =================================
  */
$z-low: 10;
$z-mid: 100;
$z-high: 1000;

/**
  * App-specific variables
  * =================================
  */
$default-transition-timing: cubic-bezier(0.77, 0, 0.175, 1);
$default-transition-duration: 300ms;
$default-transition: all $default-transition-duration $default-transition-timing;

$font-family-fancy: "Indie Flower", system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family: "Montserrat Alternates", system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$page-content-max-width: 1200px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
$breakpoints: (
  narrow: 400px,
  mid: 750px,
  wide: 1000px,
  huge: 1250px,
);
//element sizing
$box-border-radius: 0px;
$element-height: 3rem;
