@import "include-media";

$phone-proportions: 0.75;
@mixin font-size($sizeValue: 1.6) {
  // font-size: + px;
  $size: $sizeValue;
  $phoneSize: $sizeValue * $phone-proportions;

  font-size: $size + rem;
  @include media("<mid") {
    font-size: $phoneSize + rem;
  }
}

@mixin line-height($sizeValue: 1.6) {
  // font-size: + px;
  $size: $sizeValue;
  $phoneSize: $sizeValue * $phone-proportions;

  line-height: $size + rem;
  @include media("<mid") {
    line-height: $phoneSize + rem;
  }
}
