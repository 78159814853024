@import "color-palette";

$color_1: #b00020;
$color_2: rgba(0, 0, 0, 0.6);
$color_3: rgba(0, 0, 0, 0.87);
$color_4: $color-primary;
$color_5: #ffffff;
$color_6: $color-third;
$color_7: $blue;
$color_8: $green;
$color_9: #212529;
$color_10: $yellow;
$color_11: $purple;
$color_12: $red;
$color_13: #fff;
$color_14: rgba(30, 39, 86, 0.12);
$color_15: inherit;
$color_16: #01579b;
$color_17: #1b5e20;
$color_18: #7f6003;
$color_19: #b71c1c;
$color_20: rgba(255, 255, 255, 0.87);
$color_21: #f6f6f6;
$color_22: #f8f9fa;
$color_23: rgba(0, 0, 0, 0.38);
$font-family_1: var(--font-family);
$font-family_2: "PrimeIcons";
$background-color_1: rgba(0, 0, 0, 0.32);
$background-color_2: #ececec;
$background-color_3: #dcdcdc;
$background-color_4: #f5f5f5;
$background-color_5: transparent;
$background-color_6: $color-primary;
$background-color_7: #ffffff;
$background-color_8: #bdbdbd;
$background-color_9: rgba(50, 65, 145, 0.12);
$background-color_10: rgba(63, 81, 181, 0.12);
$background-color_11: inherit;
$background-color_12: rgba(0, 0, 0, 0.5);
$background-color_13: rgba(255, 255, 255, 0.1);
$background-color_14: rgba(0, 0, 0, 0.12);
$background-color_15: $pink;
$background-color_16: $green;
$background-color_17: $blue;
$background-color_18: $yellow;
$background-color_19: #d32f2f;
$background-color_20: rgba(0, 0, 0, 0.08);
$background-color_21: rgba(255, 255, 255, 0.32);
$background-color_22: rgba(63, 81, 181, 0.16);
$background-color_23: rgba(255, 64, 129, 0.16);
$background-color_24: rgba(33, 150, 243, 0.16);
$background-color_25: rgba(104, 159, 56, 0.16);
$background-color_26: rgba(251, 192, 45, 0.16);
$background-color_27: rgba(156, 39, 176, 0.16);
$background-color_28: rgba(211, 47, 47, 0.16);
$background-color_29: rgba(0, 0, 0, 0.03);
$background-color_30: rgba(0, 0, 0, 0.04);
$background-color_31: #9e9d9e;
$border-color_1: rgba(0, 0, 0, 0.87);
$border-color_2: $color-primary;
$border-color_3: #b00020;
$border-color_4: transparent;
$border-color_5: black;
$border-color_6: #ffffff;
$border-color_7: var(--color-front);
$border-color_8: rgba(0, 0, 0, 0.12);
$border-color_9: #e0e0e1;
$border-color_10: #d9d8d9;
$border-color_11: 0 none;
$border-color_12: rgba(0, 0, 0, 0.6);
$border-color_13: #e0e0e0;
$border-color_14: rgba(63, 81, 181, 0.12);
$border-color_15: transparent transparent rgba(0, 0, 0, 0.12) transparent;
$border-color_16: rgba(255, 255, 255, 0);
$border-color_17: rgba(0, 0, 0, 0.38);
$border-color_18: #757575;
$border-top-color_1: #ffffff;
$border-top-color_2: rgba(97, 97, 97, 0.9);
$border-right-color_1: rgba(97, 97, 97, 0.9);
$border-right-color_2: transparent;
$border-bottom-color_1: #ffffff;
$border-bottom-color_2: var(--color-front);
$border-bottom-color_3: rgba(97, 97, 97, 0.9);
$border-left-color_1: rgba(97, 97, 97, 0.9);
$outline-color_1: transparent;

h2 {
  @extend .h2;
  display: inline;
  border-width: 0 0 7px;
  border-style: solid;
  border-image: linear-gradient(
      45deg,
      darken($color-fifth, 11),
      darken($color-secondary, 1)
    )
    10% round;
  ::after,
  ::before {
    content: "";
    display: block;
    margin: 1em 0
  }
}

.dialog {


  .content {
    padding: 1.5rem 0px;
  }

  .footer {
    border-top: 0;
    padding: 1.5rem 0px;
    text-align: right;


    display: flex;
    justify-content: flex-end;
    gap:0.5rem ;
  }

  .footer button {

    width: auto;
  }
}
.p-dialog .p-dialog-footer button{
  margin:0 !important;
}

::ng-deep .p-confirm-popup {
  -webkit-box-shadow: 5px 5px 0px 0px #289fed, 10px 10px 0px 0px #5fb8ff,
    15px 15px 0px 0px #a1d8ff, 20px 20px 0px 0px #cae6ff,
    25px 25px 0px 0px #e1eeff, 5px 5px 15px 5px rgba(0, 0, 0, 0) !important;
  box-shadow: 5px 5px 0px 0px #289fed, 10px 10px 0px 0px #5fb8ff,
    15px 15px 0px 0px #a1d8ff, 20px 20px 0px 0px #cae6ff,
    25px 25px 0px 0px #e1eeff, 5px 5px 15px 5px rgba(0, 0, 0, 0) !important;
}

@keyframes p-component-overlay-enter-animation {
  from {
    opacity: 0;
  }
  to {
    opacity:1
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    opacity: 1;
  }
  to {
    opacity:0
  }

}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 4px;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 4px;
    height: 10px;
    border-color: #ffffff;
    transform: translate3d(0, -10px, 0) rotate(45deg);
  }
}
/* Customizations to the designer theme should be defined here */
:root {
  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: rgba(0, 0, 0, 0.04);
  --surface-d: rgba(0, 0, 0, 0.12);
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: rgba(0, 0, 0, 0.87);
  --text-color-secondary: rgba(0, 0, 0, 0.6);
  --primary-color: $color-primary;
  --primary-color-text: #ffffff;
  --font-family: #{$font-family};
  --blue-50: #f4fafe;
  --blue-100: #cae6fc;
  --blue-200: #a0d2fa;
  --blue-300: #75bef8;
  --blue-400: #4baaf5;
  --blue-500: #2196f3;
  --blue-600: #1c80cf;
  --blue-700: #1769aa;
  --blue-800: #125386;
  --blue-900: #0d3c61;
  --green-50: #f7faf5;
  --green-100: #dbe8cf;
  --green-200: #bed6a9;
  --green-300: #a1c384;
  --green-400: #85b15e;
  --green-500: #689f38;
  --green-600: #588730;
  --green-700: #496f27;
  --green-800: #39571f;
  --green-900: #2a4016;
  --yellow-50: #fffcf5;
  --yellow-100: #fef0cd;
  --yellow-200: #fde4a5;
  --yellow-300: #fdd87d;
  --yellow-400: #fccc55;
  --yellow-500: #fbc02d;
  --yellow-600: #d5a326;
  --yellow-700: #b08620;
  --yellow-800: #8a6a19;
  --yellow-900: #644d12;
  --cyan-50: #f2fcfd;
  --cyan-100: #c2eff5;
  --cyan-200: #91e2ed;
  --cyan-300: #61d5e4;
  --cyan-400: #30c9dc;
  --cyan-500: #00bcd4;
  --cyan-600: #00a0b4;
  --cyan-700: #008494;
  --cyan-800: #006775;
  --cyan-900: #004b55;
  --pink-50: #fef4f7;
  --pink-100: #fac9da;
  --pink-200: #f69ebc;
  --pink-300: #f1749e;
  --pink-400: #ed4981;
  --pink-500: #e91e63;
  --pink-600: #c61a54;
  --pink-700: #a31545;
  --pink-800: #801136;
  --pink-900: #5d0c28;
  --indigo-50: #f6f7fc;
  --indigo-100: #d5d9ef;
  --indigo-200: #b3bae2;
  --indigo-300: #919cd5;
  --indigo-400: #707dc8;
  --indigo-500: #4e5fbb;
  --indigo-600: #42519f;
  --indigo-700: #374383;
  --indigo-800: #2b3467;
  --indigo-900: #1f264b;
  --teal-50: #f2faf9;
  --teal-100: #c2e6e2;
  --teal-200: #91d2cc;
  --teal-300: #61beb5;
  --teal-400: #30aa9f;
  --teal-500: #009688;
  --teal-600: #008074;
  --teal-700: #00695f;
  --teal-800: #00534b;
  --teal-900: #003c36;
  --orange-50: #fffaf2;
  --orange-100: #ffe6c2;
  --orange-200: #ffd391;
  --orange-300: #ffbf61;
  --orange-400: #ffac30;
  --orange-500: #ff9800;
  --orange-600: #d98100;
  --orange-700: #b36a00;
  --orange-800: #8c5400;
  --orange-900: #663d00;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --purple-50: #faf4fb;
  --purple-100: #e7cbec;
  --purple-200: #d4a2dd;
  --purple-300: #c279ce;
  --purple-400: #af50bf;
  --purple-500: #9c27b0;
  --purple-600: #852196;
  --purple-700: #6d1b7b;
  --purple-800: #561561;
  --purple-900: #3e1046;
  --red-50: #fef6f5;
  --red-100: #fcd2cf;
  --red-200: #faaea9;
  --red-300: #f88a82;
  --red-400: #f6675c;
  --red-500: #f44336;
  --red-600: #cf392e;
  --red-700: #ab2f26;
  --red-800: #86251e;
  --red-900: #621b16;
  --primary-50: #f5f6fb;
  --primary-100: #d1d5ed;
  --primary-200: #acb4df;
  --primary-300: #8893d1;
  --primary-400: #6372c3;
  --primary-500: $color-primary;
  --primary-600: #36459a;
  --primary-700: #2c397f;
  --primary-800: #232d64;
  --primary-900: #192048;
}

.p-component {
  font-family: $font-family_1;
  font-size: 1rem;
  font-weight: normal;
  &:disabled {
    opacity: 0.38;
  }
}
.p-component-overlay {
  background-color: $background-color_1;
  transition-duration: 0.2s;
}
.p-disabled {
  opacity: 0.38;
}
.p-error {
  color: var(--color-front-lightest);
}
.p-text-secondary {
  color: var(--color-front-lighter);
}
.pi {
  font-size: 1rem;
}
.p-link {
  font-size: 1rem;
  font-family: $font-family_1;

  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
}
.p-component-overlay-enter {

  animation: p-component-overlay-enter-animation 150ms
}
.p-component-overlay-leave {

  animation: p-component-overlay-leave-animation 150ms
}
.p-autocomplete {
  .p-autocomplete-loader {
    right: 1rem;
  }
  .p-autocomplete-multiple-container {
    padding: 0.5rem 1rem;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: $border-color_2;
      box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
        inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
    }
    .p-autocomplete-input-token {
      padding: 0.5rem 0;
      input {
        font-family: $font-family_1;
        font-size: 1rem;
        color: var(--color-front);
        padding: 0;
        margin: 0;
      }
    }
    .p-autocomplete-token {
      padding: 0.5rem 1rem;
      margin-right: 0.5rem;
      background: rgba(63, 81, 181, 0.12);
      color: $color_4;

      .p-autocomplete-token-icon {
        margin-left: 0.5rem;
      }
    }
  }
}
.p-autocomplete.p-autocomplete-dd {
  .p-autocomplete-loader {
    right: 4rem;
  }
}
p-autocomplete.ng-dirty.ng-invalid {
  > .p-autocomplete {
    > .p-inputtext {
      border-color: $border-color_3;
    }
  }
  .p-autocomplete {
    > .p-inputtext {
      &:enabled {
        &:focus {
          border: 4px solid var(--color-front);
        }
      }
    }
    .p-autocomplete-multiple-container {
      &:not(.p-disabled).p-focus {
        border: 4px solid var(--color-front);
      }
    }
  }
}
.p-autocomplete-panel {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  .p-autocomplete-items {
    padding: 0;
    .p-autocomplete-item {
      margin: 0;
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
      transition: none;

      &:hover {
        color: var(--color-front);
        background: rgba(0, 0, 0, 0.04);
      }
    }
    .p-autocomplete-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
    .p-autocomplete-empty-message {
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
    }
    .p-autocomplete-item-group {
      margin: 0;
      padding: 1rem;
      color: var(--color-front-lighter);
      background: var(--color-back-lighter);
      font-weight: 400;
    }
  }
}
p-autocomplete.p-autocomplete-clearable {
  .p-inputtext {
    padding-right: 3rem;
  }
  .p-autocomplete-clear-icon {
    color: var(--color-front-lighter);
    right: 1rem;
  }
  .p-autocomplete-dd {
    .p-autocomplete-clear-icon {
      color: var(--color-front-lighter);
      right: 4rem;
    }
  }
}
p-calendar.ng-dirty.ng-invalid {
  > .p-calendar {
    > .p-inputtext {
      border-color: $border-color_3;
    }
  }
  .p-inputtext {
    &:enabled {
      &:focus {
        border: 4px solid var(--color-front);
      }
    }
  }
  .p-calendar-w-btn {
    border-color: $border-color_3;
    .p-inputtext {
      &:enabled {
        &:focus {
          box-shadow: none;
        }
      }
    }
    &:not(.p-disabled).p-focus {
      border: 4px solid var(--color-front);
    }
  }
}
.p-datepicker {
  padding: 0.5rem;
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 1px solid #e5e5e5;

  &:not(.p-datepicker-inline) {
    background: var(--color-back-lighter);
    border: 4px solid var(--color-front);
    .p-datepicker-header {
      background: var(--color-back-lighter);
    }
  }
  .p-datepicker-header {
    padding: 0.5rem;
    color: var(--color-front);
    background: var(--color-back-lighter);
    font-weight: 500;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);


    border-bottom: 0 none;
    .p-datepicker-prev {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      order: 2;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    .p-datepicker-next {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      order: 3;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    .p-datepicker-title {
      line-height: 2.5rem;
      margin: 0 auto 0 0;
      order: 1;
      .p-datepicker-year {
        color: var(--color-front);
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        font-weight: 500;
        padding: 0.5rem;
        &:enabled {
          &:hover {
            color: $color_4;
          }
        }
      }
      .p-datepicker-month {
        color: var(--color-front);
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        font-weight: 500;
        padding: 0.5rem;
        margin-right: 0.5rem;
        &:enabled {
          &:hover {
            color: $color_4;
          }
        }
      }
    }
  }
  table {
    font-size: 1rem;
    margin: 0.5rem 0;
    th {
      padding: 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      color: $color_23 var(--color-front-darker);
      font-weight: 400;
      font-size: 0.875rem;
      > span {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    td {
      padding: 0.5rem;
      > span {
        width: 2.5rem;
        height: 2.5rem;

        transition: none;
        border: 1px solid transparent;
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }
      > span.p-highlight {
        color: $color_4;
        background: rgba(63, 81, 181, 0.12);
      }
    }
    td.p-datepicker-today {
      > span {
        background: var(--color-back-lighter);
        color: var(--color-front);
        border-color: $border-color_5;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.38);
      }
      > span.p-highlight {
        color: $color_4;
        background: rgba(63, 81, 181, 0.12);
      }
    }
    td.p-datepicker-today.p-highlight {
      box-shadow: 0 0 0 1px rgba(63, 81, 181, 0.12);
    }
  }
  .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    .p-button {
      width: auto;
    }
  }
  .p-timepicker {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0.5rem;
    button {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
      &:last-child {
        margin-top: 0.2em;
      }
    }
    span {
      font-size: 1.25rem;
    }
    > div {
      padding: 0 0.5rem;
    }
  }
  .p-monthpicker {
    margin: 0.5rem 0;
    .p-monthpicker-month {
      padding: 0.5rem;
      transition: none;

    }
    .p-monthpicker-month.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
  }
  .p-yearpicker {
    margin: 0.5rem 0;
    .p-yearpicker-year {
      padding: 0.5rem;
      transition: none;

    }
    .p-yearpicker-year.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
  }
  &:not(.p-disabled) {
    table {
      td {
        span {
          &:not(.p-highlight) {
            &:not(.p-disabled) {
              &:hover {
                background: rgba(0, 0, 0, 0.04);
              }
              &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled) {
          &:not(.p-highlight) {
            &:hover {
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
      }
    }
    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled) {
          &:not(.p-highlight) {
            &:hover {
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
      }
    }
  }
}
.p-datepicker.p-datepicker-timeonly {
  .p-timepicker {
    border-top: 0 none;
  }
}
.p-datepicker.p-datepicker-multiple-month {
  .p-datepicker-group {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    &:first-child {
      padding-left: 0;
      border-left: 0 none;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
p-calendar.p-calendar-clearable {
  .p-inputtext {
    padding-right: 3rem;
  }
  .p-calendar-clear-icon {
    color: var(--color-front-lighter);
    right: 1rem;
  }
}
p-calendar.p-calendar-clearable.p-calendar-w-btn {
  .p-calendar-clear-icon {
    color: var(--color-front-lighter);
    right: 4rem;
  }
}
.p-cascadeselect {
  background: var(--color-back-lighter);
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: $border-color_2;
    box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
      inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
  }
  .p-cascadeselect-label {
    background: transparent;
    padding: 1rem 1rem;
    &:enabled {
      &:focus {
        outline: 0 none;
        box-shadow: none;
      }
    }
  }
  .p-cascadeselect-label.p-placeholder {
    color: var(--color-front-lighter);
  }
  .p-cascadeselect-trigger {
    background: transparent;
    color: var(--color-front-lighter);
    width: 2.357rem;


    background-image: none;
    background: transparent;
  }
  .p-inputtext {
    background-image: none;
    background: transparent;
  }
}
.p-cascadeselect-panel {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  .p-cascadeselect-items {
    padding: 0;
    .p-cascadeselect-item {
      margin: 0;
      color: var(--color-front);
      background: transparent;
      transition: none;

      .p-cascadeselect-item-content {
        padding: 1rem 1rem;
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: var(--color-front);
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      .p-cascadeselect-group-icon {
        font-size: 0.875rem;
      }
    }
    .p-cascadeselect-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
  }
}
p-cascadeselect.ng-dirty.ng-invalid {
  > .p-cascadeselect {
    border-color: $border-color_3;
  }
  .p-cascadeselect {
    &:not(.p-disabled).p-focus {
      border: 4px solid var(--color-front);
    }
  }
}
.p-input-filled {
  .p-cascadeselect {
    background: #f5f5f5;


    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
        background-color: $background-color_2;
        border-color: $border-color_4;
        background-image: linear-gradient(
            to bottom,
            $color-primary,
            $color-primary
          ),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_3;
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
    .p-inputtext {
      background-image: none;
      background-color: $background-color_5;
      &:enabled {
        &:hover {
          background-image: none;
          background: transparent;
        }
        &:focus {
          background-image: none;
          background: transparent;
        }
      }
    }
    &:not(.p-disabled).p-inputwrapper-focus {
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
    .p-cascadeselect-label {
      &:hover {
        background-image: none;
        background: transparent;
      }
    }
  }
  .p-checkbox {
    .p-checkbox-box {
      background-color: $background-color_4;
      background-color: var(--color-back-lighter);
      &:not(.p-disabled) {
        &:hover {
          background-color: var(--color-back-lighter);
        }
      }
    }
    .p-checkbox-box.p-highlight {
      background: $color-primary;
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: $background-color_2;
        }
      }
      .p-checkbox-box.p-highlight {
        &:hover {
          background: $color-primary;
        }
      }
    }
  }
  .p-dropdown {
    background: #f5f5f5;


    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
        background-color: $background-color_2;
        border-color: $border-color_4;
        background-image: linear-gradient(
            to bottom,
            $color-primary,
            $color-primary
          ),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_3;
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
      .p-inputtext {
        background-color: $background-color_5;
      }
    }
    .p-inputtext {
      background-image: none;
      background-color: $background-color_5;
      &:enabled {
        &:hover {
          background-image: none;
          background: transparent;
        }
        &:focus {
          background-image: none;
          background: transparent;
        }
      }
    }
    &:not(.p-disabled).p-inputwrapper-focus {
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
  }
  .p-inputtext {
    background-color: $background-color_4;


    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    &:enabled {
      &:hover {
        background-color: $background-color_2;
        background-color: $background-color_2;
        border-color: $border-color_4;
        background-image: linear-gradient(
            to bottom,
            $color-primary,
            $color-primary
          ),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
      }
      &:focus {
        background-color: $background-color_3;
        box-shadow: none;
        background-color: $background-color_3;
        border-color: $border-color_4;
        background-size: 100% 2px, 100% 1px;
      }
    }
  }
  .p-multiselect {
    background: #f5f5f5;


    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
        background-color: $background-color_2;
        border-color: $border-color_4;
        background-image: linear-gradient(
            to bottom,
            $color-primary,
            $color-primary
          ),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_3;
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
    .p-inputtext {
      background-image: none;
      background-color: $background-color_5;
      &:enabled {
        &:hover {
          background-image: none;
          background: transparent;
        }
        &:focus {
          background-image: none;
          background: transparent;
        }
      }
    }
    &:not(.p-disabled).p-inputwrapper-focus {
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
    .p-multiselect-label {
      &:hover {
        background-image: none;
        background: transparent;
      }
    }
  }
  .p-radiobutton {
    .p-radiobutton-box {
      background-color: $background-color_4;
      background-color: var(--color-back-lighter);
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
          background-color: var(--color-back-lighter);
        }
      }
    }
    .p-radiobutton-box.p-highlight {
      background: var(--color-back-lighter);
      &:not(.p-disabled) {
        &:hover {
          background: var(--color-back-lighter);
        }
      }
    }
  }
  .p-treeselect {
    background: #f5f5f5;


    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
        background-color: $background-color_2;
        border-color: $border-color_4;
        background-image: linear-gradient(
            to bottom,
            $color-primary,
            $color-primary
          ),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_3;
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
    .p-inputtext {
      background-image: none;
      background-color: $background-color_5;
      &:enabled {
        &:hover {
          background-image: none;
          background: transparent;
        }
        &:focus {
          background-image: none;
          background: transparent;
        }
      }
    }
    &:not(.p-disabled).p-inputwrapper-focus {
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
    .p-treeselect-label {
      &:hover {
        background-image: none;
        background: transparent;
      }
    }
  }
  .p-autocomplete {
    .p-autocomplete-multiple-container {


      border: 1px solid transparent;
      background: #f5f5f5 no-repeat;
      background-image: linear-gradient(
          to bottom,
          $color-primary,
          $color-primary
        ),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
      background-size: 0 2px, 100% 1px;
      background-position: 50% 100%, 50% 100%;
      background-origin: border-box;
      .p-inputtext {
        background-image: none;
        background-color: $background-color_5;
        &:enabled {
          &:hover {
            background-image: none;
            background: transparent;
          }
          &:focus {
            background-image: none;
            background: transparent;
          }
        }
      }
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
          border-color: $border-color_4;
          background-image: linear-gradient(
              to bottom,
              $color-primary,
              $color-primary
            ),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
        }
      }
      &:not(.p-disabled).p-focus {
        box-shadow: none;
        background-color: $background-color_3;
        border-color: $border-color_4;
        background-size: 100% 2px, 100% 1px;
      }
      &:not(.p-disabled).p-inputwrapper-focus {
        box-shadow: none;
        background-color: $background-color_3;
        border-color: $border-color_4;
        background-size: 100% 2px, 100% 1px;
      }
    }
  }
  .p-float-label {
    .p-autocomplete {
      .p-autocomplete-multiple-container {
        .p-autocomplete-token {
          padding-top: 0;
          padding-bottom: 0;
          .p-autocomplete-token-icon {
            font-size: 75%;
          }
        }
        .p-autocomplete-input-token {
          padding: 0;
        }
      }
    }
    .p-cascadeselect {
      .p-cascadeselect-label {
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
      }
    }
    .p-inputwrapper-filled {
      .p-cascadeselect {
        .p-cascadeselect-label {
          padding-top: 1.5rem;
          padding-bottom: 0.5rem;
        }
      }
      ~ {
        label {
          top: 0.25rem !important;
          margin-top: 0;
          background: transparent;
        }
      }
      .p-multiselect {
        .p-multiselect-label {
          padding-top: 1.5rem;
          padding-bottom: 0.5rem;
        }
      }
      .p-multiselect.p-multiselect-chip {
        .p-multiselect-token {
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          .p-multiselect-token-icon {
            font-size: 75%;
          }
        }
      }
      .p-treeselect {
        .p-treeselect-label {
          padding-top: 1.5rem;
          padding-bottom: 0.5rem;
        }
      }
      .p-treeselect.p-treeselect-chip {
        .p-treeselect-token {
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          .p-treeselect-token-icon {
            font-size: 75%;
          }
        }
      }
    }
    .p-chips {
      .p-chips-multiple-container {
        .p-chips-token {
          padding-top: 0;
          padding-bottom: 0;
          .p-chips-token-icon {
            font-size: 75%;
          }
        }
        .p-chips-input-token {
          padding: 0;
        }
      }
    }
    .p-inputtext {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
    }
    input {
      &:focus {
        ~ {
          label {
            top: 0.25rem !important;
            margin-top: 0;
            background: transparent;
          }
        }
      }
    }
    input.p-filled {
      ~ {
        label {
          top: 0.25rem !important;
          margin-top: 0;
          background: transparent;
        }
      }
    }
    textarea {
      &:focus {
        ~ {
          label {
            top: 0.25rem !important;
            margin-top: 0;
            background: transparent;
          }
        }
      }
    }
    textarea.p-filled {
      ~ {
        label {
          top: 0.25rem !important;
          margin-top: 0;
          background: transparent;
        }
      }
    }
    .p-inputwrapper-focus {
      ~ {
        label {
          top: 0.25rem !important;
          margin-top: 0;
          background: transparent;
        }
      }
    }
    .p-multiselect {
      .p-multiselect-label {
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
      }
    }
    .p-treeselect {
      .p-treeselect-label {
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
  p-autocomplete.ng-dirty.ng-invalid {
    .p-autocomplete {
      > .p-inputtext {
        border-color: $border-color_4;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
        &:enabled {
          &:focus {
            box-shadow: none;
            border-color: $border-color_4;
          }
        }
      }
      .p-autocomplete-multiple-container {
        border-color: $border-color_4;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
        &:not(.p-disabled) {
          &:hover {
            background-image: linear-gradient(to bottom, #b00020, #b00020),
              linear-gradient(to bottom, #b00020, #b00020);
          }
        }
        &:not(.p-disabled).p-focus {
          box-shadow: none;
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
        &:not(.p-disabled).p-inputwrapper-focus {
          box-shadow: none;
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
      }
    }
  }
  .p-calendar-w-btn {


    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    .p-inputtext {
      background-image: none;
      background-color: $background-color_5;
    }
    &:not(.p-calendar-disabled) {
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
          border-color: $border-color_4;
          background-image: linear-gradient(
              to bottom,
              $color-primary,
              $color-primary
            ),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
        }
      }
      &:not(.p-disabled).p-focus {
        box-shadow: none;
        background-color: $background-color_3;
        border-color: $border-color_4;
        background-size: 100% 2px, 100% 1px;
      }
      &:not(.p-disabled).p-inputwrapper-focus {
        box-shadow: none;
        background-color: $background-color_3;
        border-color: $border-color_4;
        background-size: 100% 2px, 100% 1px;
      }
      .p-inputtext {
        &:enabled {
          &:hover {
            background-image: none;
            background: transparent;
          }
          &:focus {
            background-image: none;
            background: transparent;
          }
        }
      }
    }
  }
  p-calendar.ng-dirty.ng-invalid {
    .p-inputtext {
      border-color: $border-color_4;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
      &:enabled {
        &:focus {
          box-shadow: none;
          border-color: $border-color_4;
        }
      }
    }
    .p-calendar-w-btn {
      border-color: $border-color_4;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
      &:not(.p-disabled) {
        &:hover {
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
      }
      &:not(.p-disabled).p-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
      &:not(.p-disabled).p-inputwrapper-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
      .p-inputtext {
        background-image: none;
      }
    }
  }
  p-cascadeselect.ng-dirty.ng-invalid {
    .p-cascadeselect {
      border-color: $border-color_4;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
      &:not(.p-disabled) {
        &:hover {
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
      }
      &:not(.p-disabled).p-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
      &:not(.p-disabled).p-inputwrapper-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
    }
  }
  .p-cascadeselect.p-invalid {
    border-color: $border-color_4;
    background-image: linear-gradient(to bottom, #b00020, #b00020),
      linear-gradient(to bottom, #b00020, #b00020);
    &:not(.p-disabled) {
      &:hover {
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
    }
    &:not(.p-disabled).p-focus {
      box-shadow: none;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
    }
    &:not(.p-disabled).p-inputwrapper-focus {
      box-shadow: none;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
    }
  }
  .p-chips-multiple-container {


    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    .p-inputtext {
      background-image: none;
      background-color: $background-color_5;
      &:enabled {
        &:hover {
          background-image: none;
          background: transparent;
        }
        &:focus {
          background-image: none;
          background: transparent;
        }
      }
    }
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
        border-color: $border-color_4;
        background-image: linear-gradient(
            to bottom,
            $color-primary,
            $color-primary
          ),
          linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
      }
    }
    &:not(.p-disabled).p-focus {
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
    &:not(.p-disabled).p-inputwrapper-focus {
      box-shadow: none;
      background-color: $background-color_3;
      border-color: $border-color_4;
      background-size: 100% 2px, 100% 1px;
    }
  }
  p-chips.ng-dirty.ng-invalid {
    .p-chips {
      .p-chips-multiple-container {
        border-color: $border-color_4;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
        &:not(.p-disabled) {
          &:hover {
            background-image: linear-gradient(to bottom, #b00020, #b00020),
              linear-gradient(to bottom, #b00020, #b00020);
          }
        }
        &:not(.p-disabled).p-focus {
          box-shadow: none;
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
        &:not(.p-disabled).p-inputwrapper-focus {
          box-shadow: none;
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
      }
    }
  }
  p-dropdown.ng-dirty.ng-invalid {
    .p-dropdown {
      border-color: $border-color_4;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
      &:not(.p-disabled) {
        &:hover {
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
      }
      &:not(.p-disabled).p-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
      &:not(.p-disabled).p-inputwrapper-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
    }
  }
  .p-inputtext.ng-invalid.ng-dirty {
    border-color: $border-color_4;
    background-image: linear-gradient(to bottom, #b00020, #b00020),
      linear-gradient(to bottom, #b00020, #b00020);
    &:enabled {
      &:focus {
        box-shadow: none;
        border-color: $border-color_4;
      }
    }
  }
  .p-inputgroup {
    .p-inputgroup-addon {


      border: 1px solid transparent;
      background: #f5f5f5 no-repeat;
      background-image: linear-gradient(
          to bottom,
          $color-primary,
          $color-primary
        ),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
      background-size: 0 2px, 100% 1px;
      background-position: 50% 100%, 50% 100%;
      background-origin: border-box;
      &:last-child {
        border-right-color: $border-right-color_2;
      }
    }

  }
  p-inputmask.ng-dirty.ng-invalid {
    .p-inputtext {
      border-color: $border-color_4;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
      &:enabled {
        &:focus {
          box-shadow: none;
          border-color: $border-color_4;
        }
      }
    }
  }
  p-inputnumber.ng-dirty.ng-invalid {
    .p-inputtext {
      border-color: $border-color_4;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
      &:enabled {
        &:focus {
          box-shadow: none;
          border-color: $border-color_4;
        }
      }
    }
  }
  p-multiselect.ng-dirty.ng-invalid {
    .p-multiselect {
      border-color: $border-color_4;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
      &:not(.p-disabled) {
        &:hover {
          background-image: linear-gradient(to bottom, #b00020, #b00020),
            linear-gradient(to bottom, #b00020, #b00020);
        }
      }
      &:not(.p-disabled).p-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
      &:not(.p-disabled).p-inputwrapper-focus {
        box-shadow: none;
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
    }
  }
  .p-treeselect.p-invalid {
    border-color: $border-color_4;
    background-image: linear-gradient(to bottom, #b00020, #b00020),
      linear-gradient(to bottom, #b00020, #b00020);
    &:not(.p-disabled) {
      &:hover {
        background-image: linear-gradient(to bottom, #b00020, #b00020),
          linear-gradient(to bottom, #b00020, #b00020);
      }
    }
    &:not(.p-disabled).p-focus {
      box-shadow: none;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
    }
    &:not(.p-disabled).p-inputwrapper-focus {
      box-shadow: none;
      background-image: linear-gradient(to bottom, #b00020, #b00020),
        linear-gradient(to bottom, #b00020, #b00020);
    }
  }
}
p-cascadeselect.p-cascadeselect-clearable {
  .p-cascadeselect-label {
    padding-right: 1rem;
  }
  .p-cascadeselect-clear-icon {
    color: var(--color-front-lighter);
    right: 2.357rem;
  }
}
.p-checkbox {
  width: 18px;
  height: 18px;

  transition: box-shadow 0.2s;
  .p-checkbox-box {
    border: 4px solid #757575;
    background: var(--color-back-lighter);
    width: 18px;
    height: 18px;
    color: var(--color-front);

    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-color: $border-color_18;

    position: relative;
    .p-checkbox-icon {
      transition-duration: 0.2s;
      color: $color_5;
      font-size: 14px;
    }
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_18;
      }
    }
    &:not(.p-disabled).p-focus {
      border-color: $border-color_18;
    }
  }
  .p-checkbox-box.p-highlight {
    border-color: $border-color_2;
    background: $color-primary;
    &:not(.p-disabled).p-focus {
      border-color: $border-color_2;
    }
    .p-checkbox-icon.pi-check {
      &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 1px;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        animation: checkbox-check 125ms 50ms linear forwards;
      }
    }
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $border-color_1;
      }
    }
    .p-checkbox-box.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: $border-color_2;
    }
    .p-checkbox-box.p-highlight {
      &:hover {
        border-color: $border-color_2;
        background: $color-primary;
        color: $color_5;
      }
    }
    &:hover {
      box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.04);
    }
  }
  &:not(.p-checkbox-disabled).p-checkbox-focused {
    box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12);
  }
}
p-checkbox.ng-dirty.ng-invalid {
  > .p-checkbox {
    > .p-checkbox-box {
      border-color: $border-color_3;
    }
  }
}
.p-checkbox-label {
  margin-left: 0.5rem;
}
.p-chips {
  .p-chips-multiple-container {
    padding: 0.5rem 1rem;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: $border-color_2;
      box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
        inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
    }
    .p-chips-token {
      padding: 0.5rem 1rem;
      margin-right: 0.5rem;
      background: rgba(63, 81, 181, 0.12);
      color: $color_4;

      .p-chips-token-icon {
        margin-left: 0.5rem;
      }
    }
    .p-chips-input-token {
      padding: 0.5rem 0;
      input {
        font-family: $font-family_1;
        font-size: 1rem;
        color: var(--color-front);
        padding: 0;
        margin: 0;
      }
    }
  }
}
p-chips.ng-dirty.ng-invalid {
  > .p-chips {
    > .p-inputtext {
      border-color: $border-color_3;
    }
  }
  .p-chips {
    .p-chips-multiple-container {
      &:not(.p-disabled).p-focus {
        border: 4px solid var(--color-front);
      }
    }
  }
}
p-chips.p-chips-clearable {
  .p-inputtext {
    padding-right: 2rem;
  }
  .p-chips-clear-icon {
    color: var(--color-front-lighter);
    right: 1rem;
  }
}
.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}
.p-fluid {
  .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
  }
  .p-inputgroup {
    .p-button {
      width: auto;
    }
    .p-button.p-button-icon-only {
      width: 3rem;
    }
  }
  .p-button {
    width: 100%;
  }
  .p-button-icon-only {
    width: 3rem;
  }
  .p-buttonset {
    display: flex;
    .p-button {
      flex: 1;
    }
  }
}
.p-colorpicker-panel {
  background: #323232;
  border: 1px solid #191919;
  .p-colorpicker-color-handle {
    border-color: $border-color_6;
  }
  .p-colorpicker-hue-handle {
    border-color: $border-color_6;
  }
}
.p-colorpicker-overlay-panel {
  border: 4px solid var(--color-front);
}
.p-dropdown {
  background: var(--color-back-lighter);
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: $border-color_2;
    box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
      inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
  }
  .p-dropdown-label {
    background: transparent;
    &:enabled {
      &:focus {
        outline: 0 none;
        box-shadow: none;
      }
    }
  }
  .p-dropdown-label.p-placeholder {
    color: var(--color-front-lighter);
  }
  .p-dropdown-trigger {
    background: transparent;
    color: var(--color-front-lighter);
    width: 2.357rem;


    background-image: none;
    background: transparent;
  }
  .p-dropdown-clear-icon {
    color: var(--color-front-lighter);
    right: 2.357rem;
  }
  .p-inputtext {
    background-image: none;
    background: transparent;
  }
}
.p-dropdown.p-dropdown-clearable {
  .p-dropdown-label {
    padding-right: 2rem;
  }
}
.p-dropdown-panel {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  .p-dropdown-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: var(--color-front);
    background: var(--color-back-lighter);
    margin: 0;


    .p-dropdown-filter {
      padding-right: 2rem;
      margin-right: -2rem;
    }
    .p-dropdown-filter-icon {
      right: 1rem;
      color: var(--color-front-lighter);
    }
  }
  .p-dropdown-items {
    padding: 0;
    .p-dropdown-item {
      margin: 0;
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
      transition: none;

      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: var(--color-front);
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
    .p-dropdown-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
    .p-dropdown-empty-message {
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
    }
    .p-dropdown-item-group {
      margin: 0;
      padding: 1rem;
      color: var(--color-front-lighter);
      background: var(--color-back-lighter);
      font-weight: 400;
    }
  }
}
p-dropdown.ng-dirty.ng-invalid {
  > .p-dropdown {
    border-color: $border-color_3;
  }
  .p-dropdown {
    &:not(.p-disabled).p-focus {
      border: 4px solid var(--color-front);
    }
  }
}
.p-editor-container {
  .p-editor-toolbar {
    background: var(--color-back-lighter);


  }
  .p-editor-toolbar.ql-snow {
    border: 1px solid #e0e0e0;
    .ql-stroke {
      stroke: rgba(0, 0, 0, 0.6);
    }
    .ql-fill {
      fill: rgba(0, 0, 0, 0.6);
    }
    .ql-picker {
      .ql-picker-label {
        color: var(--color-front-lighter);
        &:hover {
          color: var(--color-front);
          .ql-stroke {
            stroke: rgba(0, 0, 0, 0.87);
          }
          .ql-fill {
            fill: rgba(0, 0, 0, 0.87);
          }
        }
      }
    }
    .ql-picker.ql-expanded {
      .ql-picker-label {
        color: var(--color-front);
        .ql-stroke {
          stroke: rgba(0, 0, 0, 0.87);
        }
        .ql-fill {
          fill: rgba(0, 0, 0, 0.87);
        }
      }
      .ql-picker-options {
        background: var(--color-back-lighter);
        border: 4px solid var(--color-front);

        padding: 0;
        .ql-picker-item {
          color: var(--color-front);
          &:hover {
            color: var(--color-front);
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &:not(.ql-icon-picker) {
        .ql-picker-item {
          padding: 1rem 1rem;
        }
      }
    }
  }
  .p-editor-content {


    .ql-editor {
      background: var(--color-back-lighter);
      color: var(--color-front);


    }
  }
  .p-editor-content.ql-snow {
    border: 1px solid #e0e0e0;
  }
  .ql-snow.ql-toolbar {
    button {
      &:hover {
        color: var(--color-front);
        .ql-stroke {
          stroke: rgba(0, 0, 0, 0.87);
        }
        .ql-fill {
          fill: rgba(0, 0, 0, 0.87);
        }
      }
      &:focus {
        color: var(--color-front);
        .ql-stroke {
          stroke: rgba(0, 0, 0, 0.87);
        }
        .ql-fill {
          fill: rgba(0, 0, 0, 0.87);
        }
      }
    }
    button.ql-active {
      color: $color_4;
      .ql-stroke {
        stroke: $color-primary;
      }
      .ql-fill {
        fill: $color-primary;
      }
      .ql-picker-label {
        color: $color_4;
      }
    }
    .ql-picker-label.ql-active {
      color: $color_4;
      .ql-stroke {
        stroke: $color-primary;
      }
      .ql-fill {
        fill: $color-primary;
      }
      .ql-picker-label {
        color: $color_4;
      }
    }
    .ql-picker-item.ql-selected {
      color: $color_4;
      .ql-stroke {
        stroke: $color-primary;
      }
      .ql-fill {
        fill: $color-primary;
      }
      .ql-picker-label {
        color: $color_4;
      }
    }
  }
}
.p-inputgroup-addon {
  background: var(--color-back-lighter);
  color: var(--color-front-lighter);
  border-top: 1px solid rgba(0, 0, 0, 0.38);
  border-left: 1px solid rgba(0, 0, 0, 0.38);
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  padding: 1rem 1rem;
  min-width: 2.357rem;
  &:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.38);


  }

}
.p-inputgroup {
  > .p-component {

    margin: 0;
    + {
      .p-inputgroup-addon {
        border-left: 0 none;
      }
    }
    &:focus {
      z-index: 1;
      ~ {
        label {
          z-index: 1;
        }
      }
    }
  }
  > .p-element {

    margin: 0;
    + {
      .p-inputgroup-addon {
        border-left: 0 none;
      }
    }
    &:focus {
      z-index: 1;
      ~ {
        label {
          z-index: 1;
        }
      }
    }
  }
  > .p-inputwrapper {
    > .p-component {
      > .p-inputtext {

        margin: 0;
        + {
          .p-inputgroup-addon {
            border-left: 0 none;
          }
        }
        &:focus {
          z-index: 1;
          ~ {
            label {
              z-index: 1;
            }
          }
        }
      }
    }

  }
  > .p-float-label {
    > .p-component {

      margin: 0;
      + {
        .p-inputgroup-addon {
          border-left: 0 none;
        }
      }
      &:focus {
        z-index: 1;
        ~ {
          label {
            z-index: 1;
          }
        }
      }
    }
  }


}
p-inputmask.ng-dirty.ng-invalid {
  > .p-inputtext {
    border-color: $border-color_3;
  }
  .p-inputtext {
    &:enabled {
      &:focus {
        border: 4px solid var(--color-front);
      }
    }
  }
}
p-inputmask.p-inputmask-clearable {
  .p-inputtext {
    padding-right: 3rem;
  }
  .p-inputmask-clear-icon {
    color: var(--color-front-lighter);
    right: 1rem;
  }
}
p-inputnumber.ng-dirty.ng-invalid {
  > .p-inputnumber {
    > .p-inputtext {
      border-color: $border-color_3;
    }
  }
  .p-inputtext {
    &:enabled {
      &:focus {
        border: 4px solid var(--color-front);
      }
    }
  }
}
p-inputnumber.p-inputnumber-clearable {
  .p-inputnumber-input {
    padding-right: 3rem;
  }
  .p-inputnumber-clear-icon {
    color: var(--color-front-lighter);
    right: 1rem;
  }
  .p-inputnumber-buttons-stacked {
    .p-inputnumber-clear-icon {
      right: 4rem;
    }

  }
  .p-inputnumber-buttons-horizontal {
    .p-inputnumber-clear-icon {
      right: 4rem;
    }
  }

}
.p-inputswitch {
  width: 2.75rem;
  height: 1rem;
  .p-inputswitch-slider {
    background: rgba(0, 0, 0, 0.38);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

    &:before {
      background: var(--color-back-lighter);
      width: 1.5rem;
      height: 1.5rem;
      left: -1px;
      margin-top: -0.75rem;

      transition-duration: 0.2s;
      transition-property: box-shadow transform;
      border: 4px solid var(--color-front);
    }
  }
  &:not(.p-disabled) {
    &:hover {
      .p-inputswitch-slider {
        background: rgba(0, 0, 0, 0.38);
        &:before {
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12),
            0 0 1px 10px rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
.p-inputswitch.p-inputswitch-checked {
  .p-inputswitch-slider {
    &:before {
      transform: translateX(1.5rem);
      background: $color-primary;
    }
    background: rgba(63, 81, 181, 0.5);
  }
  &:not(.p-disabled) {
    &:hover {
      .p-inputswitch-slider {
        background: rgba(63, 81, 181, 0.5);
        &:before {
          box-shadow: 0 0 1px 10px rgba(63, 81, 181, 0.04),
            0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}
.p-inputswitch.p-focus {
  .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    &:before {
      box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12),
        0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
  }
  &:not(.p-disabled) {
    &:hover {
      .p-inputswitch-slider {
        &:before {
          box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12),
            0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}
p-inputswitch.ng-dirty.ng-invalid {
  > .p-inputswitch {
    border-color: $border-color_3;
  }
}
.p-inputtext {
  font-family: $font-family_1;
  font-size: 1rem;
  color: var(--color-front);
  background: var(--color-back-lighter);
  padding: 1rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  appearance: none;

  &:enabled {
    &:hover {
      border-color: $border-color_1;
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: $border-color_2;
      box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
        inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
    }
    &:focus.ng-invalid.ng-dirty {
      border: 4px solid var(--color-front);
    }
  }
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: $border-color_3;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.875rem 0.875rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 1.25rem 1.25rem;
}
.p-float-label {
  > label {
    left: 1rem;
    color: var(--color-front-lighter);
    transition-duration: 0.2s;
  }
  > .ng-invalid.ng-dirty {
    + {
      label {
        color: var(--color-front-lightest);
      }
    }
  }
  .p-autocomplete-multiple-container {
    .p-autocomplete-token {
      padding: 0.25rem 1rem;
    }
  }
  .p-chips-multiple-container {
    .p-chips-token {
      padding: 0.25rem 1rem;
    }
  }
  input {
    &:focus {
      ~ {
        label {
          top: -0.5rem !important;
          background-color: var(--color-back-lighter);
          padding: 2px 4px;
          margin-left: -4px;
          margin-top: 0;
          color: $color_4;
        }
      }
    }
  }
  input.p-filled {
    ~ {
      label {
        top: -0.5rem !important;
        background-color: var(--color-back-lighter);
        padding: 2px 4px;
        margin-left: -4px;
        margin-top: 0;
      }
    }
  }
  textarea {
    &:focus {
      ~ {
        label {
          top: -0.5rem !important;
          background-color: var(--color-back-lighter);
          padding: 2px 4px;
          margin-left: -4px;
          margin-top: 0;
        }
      }
    }
    ~ {
      label {
        margin-top: 0;
      }
    }
  }
  textarea.p-filled {
    ~ {
      label {
        top: -0.5rem !important;
        background-color: var(--color-back-lighter);
        padding: 2px 4px;
        margin-left: -4px;
        margin-top: 0;
      }
    }
  }
  .p-inputwrapper-focus {
    ~ {
      label {
        top: -0.5rem !important;
        background-color: var(--color-back-lighter);
        padding: 2px 4px;
        margin-left: -4px;
        margin-top: 0;
        color: $color_4;
      }
    }
  }
  .p-inputwrapper-filled {
    ~ {
      label {
        top: -0.5rem !important;
        background-color: var(--color-back-lighter);
        padding: 2px 4px;
        margin-left: -4px;
        margin-top: 0;
      }
    }
  }
  .p-multiselect-label {
    .p-multiselect-token {
      padding: 0.25rem 1rem;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
  .p-treeselect-label {
    .p-treeselect-token {
      padding: 0.25rem 1rem;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}
.p-input-icon-left {
  > i {
    &:first-of-type {
      left: 1rem;
      color: var(--color-front-lighter);
    }
  }
  > .p-inputtext {
    padding-left: 3rem;
  }
}
.p-input-icon-left.p-float-label {
  > label {
    left: 3rem;
  }
}
.p-input-icon-right {
  > i {
    &:last-of-type {
      right: 1rem;
      color: var(--color-front-lighter);
    }
  }
  > .p-inputtext {
    padding-right: 3rem;
  }
}
::-webkit-input-placeholder {
  color: var(--color-front-lighter);
}
:-moz-placeholder {
  color: var(--color-front-lighter);
}
::-moz-placeholder {
  color: var(--color-front-lighter);
}
:-ms-input-placeholder {
  color: var(--color-front-lighter);
}
.p-inputtext-sm {
  .p-inputtext {
    font-size: 0.875rem;
    padding: 0.875rem 0.875rem;
  }
}
.p-inputtext-lg {
  .p-inputtext {
    font-size: 1.25rem;
    padding: 1.25rem 1.25rem;
  }
}
.p-listbox {
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 1px solid #e5e5e5;

  .p-listbox-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: var(--color-front);
    background: var(--color-back-lighter);
    margin: 0;


    .p-listbox-filter {
      padding-right: 2rem;
    }
    .p-listbox-filter-icon {
      right: 1rem;
      color: var(--color-front-lighter);
    }
    .p-checkbox {
      margin-right: 0.5rem;
    }
  }
  .p-listbox-list {
    padding: 0;
    .p-listbox-item {
      margin: 0;
      padding: 1rem 1rem;
      color: var(--color-front);
      transition: none;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        background: rgba(0, 0, 0, 0.12);
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
      .p-ink {
        background-color: $background-color_22;
      }
      &:focus.p-highlight {
        background: rgba(63, 81, 181, 0.24);
      }
    }
    .p-listbox-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
    .p-listbox-item-group {
      margin: 0;
      padding: 1rem;
      color: var(--color-front-lighter);
      background: var(--color-back-lighter);
      font-weight: 400;
    }
    .p-listbox-empty-message {
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
    }
  }
  &:not(.p-disabled) {
    .p-listbox-item {
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: var(--color-front);
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
  }
}
p-listbox.ng-dirty.ng-invalid {
  > .p-listbox {
    border-color: $border-color_3;
  }
}
.p-multiselect {
  background: var(--color-back-lighter);
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: $border-color_2;
    box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
      inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
  }
  .p-multiselect-label {
    padding: 1rem 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    background-image: none;
    background: transparent;
  }
  .p-multiselect-label.p-placeholder {
    color: var(--color-front-lighter);
  }
  .p-multiselect-trigger {
    background: transparent;
    color: var(--color-front-lighter);
    width: 2.357rem;


    background-image: none;
    background: transparent;
  }
}
.p-multiselect.p-multiselect-chip {
  .p-multiselect-token {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    background: rgba(63, 81, 181, 0.12);
    color: $color_4;

    .p-multiselect-token-icon {
      margin-left: 0.5rem;
    }
  }
}
.p-inputwrapper-filled {
  .p-multiselect.p-multiselect-chip {
    .p-multiselect-label {
      padding: 0.5rem 1rem;
    }
  }
  .p-treeselect.p-treeselect-chip {
    .p-treeselect-label {
      padding: 0.5rem 1rem;
    }
  }
}
.p-multiselect-panel {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  .p-multiselect-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: var(--color-front);
    background: var(--color-back-lighter);
    margin: 0;


    .p-multiselect-filter-container {
      .p-inputtext {
        padding-right: 2rem;
      }
      .p-multiselect-filter-icon {
        right: 1rem;
        color: var(--color-front-lighter);
      }
    }
    .p-checkbox {
      margin-right: 0.5rem;
    }
    .p-multiselect-close {
      margin-left: 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
  .p-multiselect-items {
    padding: 0;
    .p-multiselect-item {
      margin: 0;
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
      transition: none;

      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: var(--color-front);
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        background: rgba(0, 0, 0, 0.12);
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
      .p-ink {
        background-color: $background-color_22;
      }
      &:focus.p-highlight {
        background: rgba(63, 81, 181, 0.24);
      }
    }
    .p-multiselect-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
    .p-multiselect-item-group {
      margin: 0;
      padding: 1rem;
      color: var(--color-front-lighter);
      background: var(--color-back-lighter);
      font-weight: 400;
    }
    .p-multiselect-empty-message {
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
    }
  }
  .p-multiselect-close {
    &:focus {
      background: rgba(0, 0, 0, 0.12);
    }
  }
}
p-multiselect.ng-dirty.ng-invalid {
  > .p-multiselect {
    border-color: $border-color_3;
  }
  .p-multiselect {
    &:not(.p-disabled).p-focus {
      border: 4px solid var(--color-front);
    }
  }
}
p-multiselect.p-multiselect-clearable {
  .p-multiselect-label-container {
    padding-right: 2rem;
  }
  .p-multiselect-clear-icon {
    color: var(--color-front-lighter);
    right: 2.357rem;
  }
}
p-password.ng-invalid.ng-dirty {
  > .p-password {
    > .p-inputtext {
      border-color: $border-color_3;
    }
  }
}
.p-password-panel {
  padding: 1rem;
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 4px solid var(--color-front);

  .p-password-meter {
    margin-bottom: 0.5rem;
    background: rgba(63, 81, 181, 0.32);
    .p-password-strength.weak {
      background: #d32f2f;
    }
    .p-password-strength.medium {
      background: #fbc02d;
    }
    .p-password-strength.strong {
      background: #689f38;
    }
  }
}
p-password.p-password-clearable {
  .p-password-input {
    padding-right: 3rem;
  }
  .p-password-clear-icon {
    color: var(--color-front-lighter);
    right: 1rem;
  }
}
p-password.p-password-clearable.p-password-mask {
  .p-password-input {
    padding-right: 5rem;
  }
  .p-password-clear-icon {
    color: var(--color-front-lighter);
    right: 3rem;
  }
}
.p-radiobutton {
  width: 20px;
  height: 20px;

  transition: box-shadow 0.2s;
  .p-radiobutton-box {
    border: 4px solid #757575;
    background: var(--color-back-lighter);
    width: 20px;
    height: 20px;
    color: var(--color-front);

    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          border-color: $border-color_1;
          border: 4px solid #757575;
        }
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: $border-color_2;
      border: 4px solid #757575;
    }
    .p-radiobutton-icon {
      width: 10px;
      height: 10px;
      transition-duration: 0.2s;
      background-color: $background-color_6;
    }
  }
  .p-radiobutton-box.p-highlight {
    border-color: $border-color_2;
    background: var(--color-back-lighter);
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_2;
        background: var(--color-back-lighter);
        color: $color_4;
      }
    }
    &:not(.p-disabled).p-focus {
      border-color: $border-color_2;
    }
  }
  &:not(.p-radiobutton-disabled) {
    &:hover {
      box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.04);
    }
  }
  &:not(.p-radiobutton-disabled).p-radiobutton-focused {
    box-shadow: 0 0 1px 10px rgba(0, 0, 0, 0.12);
  }
}
p-radiobutton.ng-dirty.ng-invalid {
  > .p-radiobutton {
    > .p-radiobutton-box {
      border-color: $border-color_3;
    }
  }
}
.p-radiobutton-label {
  margin-left: 0.5rem;
}
.p-rating {
  .p-rating-icon {
    color: $color_4;
    margin-left: 0.5rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    font-size: 1.143rem;

    width: 2rem;
    height: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      background: rgba(63, 81, 181, 0.12);
    }
    &:first-child {
      margin-left: 0;
    }
  }
  .p-rating-icon.p-rating-cancel {
    color: var(--color-front-lightest);
    &:focus {
      background: rgba(176, 0, 32, 0.12);
    }
  }
  .p-rating-icon.pi-star-fill {
    color: $color_4;
  }
  &:not(.p-disabled) {
    &:not(.p-readonly) {
      .p-rating-icon {
        &:hover {
          color: $color_4;
          background-color: $background-color_30;
        }
      }
      .p-rating-icon.p-rating-cancel {
        &:hover {
          color: var(--color-front-lightest);
          background: rgba(176, 0, 32, 0.04);
        }
      }
    }
  }
}
.p-selectbutton {
  .p-button {
    background: var(--color-back-lighter);


    color: var(--color-front);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    .p-button-icon-left {
      color: var(--color-front-lighter);
    }
    .p-button-icon-right {
      color: var(--color-front-lighter);
    }
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: #f6f6f6;
          color: var(--color-front);
          .p-button-icon-left {
            color: var(--color-front-lighter);
          }
          .p-button-icon-right {
            color: var(--color-front-lighter);
          }
        }
      }
    }
    &:focus {
      background: #e0e0e1;
      border-color: var(--color-front);
    }
    &:focus.p-highlight {
      background: #d9d8d9;
      border-color: var(--color-front);
    }
  }
  .p-button.p-highlight {
    background: #e0e0e1;
    color: var(--color-front);
    .p-button-icon-left {
      color: var(--color-front-lighter);
    }
    .p-button-icon-right {
      color: var(--color-front-lighter);
    }
    &:hover {
      background: #d9d8d9;
      color: var(--color-front);
      .p-button-icon-left {
        color: var(--color-front-lighter);
      }
      .p-button-icon-right {
        color: var(--color-front-lighter);
      }
    }
  }
}

.p-slider {
  background: #c1c1c1;

  .p-slider-handle {
    height: 20px;
    width: 20px;
    background: $color-primary;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    transition: transform 0.2s, box-shadow 0.2s;
    transform: scale(0.7);
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      box-shadow: 0 0 1px 10px rgba(255, 64, 129, 0.2);
    }
  }
  .p-slider-range {
    background: $color-primary;
  }
  &:not(.p-disabled) {
    .p-slider-handle {
      &:hover {
        background: $color-primary;
        border-color: $border-color_11;
      }
    }
  }
}
.p-slider.p-slider-horizontal {
  height: 2px;
  .p-slider-handle {
    margin-top: -10px;
    margin-left: -10px;
  }
}
.p-slider.p-slider-vertical {
  width: 2px;
  .p-slider-handle {
    margin-left: -10px;
    margin-bottom: -10px;
  }
}
.p-slider.p-slider-animate.p-slider-horizontal {
  .p-slider-handle {
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1),
      left 0.2s;
  }
  .p-slider-range {
    transition: width 0.2s;
  }
}
.p-slider.p-slider-animate.p-slider-vertical {
  .p-slider-handle {
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1),
      bottom 0.2s;
  }
  .p-slider-range {
    transition: height 0.2s;
  }
}
.p-togglebutton.p-button {
  background: var(--color-back-lighter);
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: var(--color-front);
  border-color: var(--color-front);

  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  .p-button-icon-left {
    color: var(--color-front-lighter);
  }
  .p-button-icon-right {
    color: var(--color-front-lighter);
  }
  &:not(.p-disabled) {
    &:not(.p-highlight) {
      &:hover {
        background: #f6f6f6;
        border-color: var(--color-front);
        color: var(--color-front);
        .p-button-icon-left {
          color: var(--color-front-lighter);
        }
        .p-button-icon-right {
          color: var(--color-front-lighter);
        }
      }
    }
  }
  &:focus {
    background: #e0e0e1;
    border-color: var(--color-front);
  }
  &:focus.p-highlight {
    background: #d9d8d9;
    border-color: var(--color-front);
  }
}
.p-togglebutton.p-button.p-highlight {
  background: #e0e0e1;
  border-color: var(--color-front);
  color: var(--color-front);
  .p-button-icon-left {
    color: var(--color-front-lighter);
  }
  .p-button-icon-right {
    color: var(--color-front-lighter);
  }
  &:hover {
    background: #d9d8d9;
    border-color: $border-color_10;
    color: var(--color-front);
    .p-button-icon-left {
      color: var(--color-front-lighter);
    }
    .p-button-icon-right {
      color: var(--color-front-lighter);
    }
  }
}
p-togglebutton.ng-dirty.ng-invalid {
  > .p-togglebutton.p-button {
    border-color: $border-color_3;
  }
}
.p-treeselect {
  background: var(--color-back-lighter);
  border: 1px solid rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: $border-color_2;
    box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
      inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
  }
  .p-treeselect-label {
    padding: 1rem 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    background-image: none;
    background: transparent;
  }
  .p-treeselect-label.p-placeholder {
    color: var(--color-front-lighter);
  }
  .p-treeselect-trigger {
    background: transparent;
    color: var(--color-front-lighter);
    width: 2.357rem;


    background-image: none;
    background: transparent;
  }
}
.p-treeselect.p-treeselect-chip {
  .p-treeselect-token {
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    background: rgba(0, 0, 0, 0.12);
    color: var(--color-front);

  }
}
p-treeselect.ng-invalid.ng-dirty {
  > .p-treeselect {
    border-color: $border-color_3;
  }
}
.p-treeselect-panel {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  .p-treeselect-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: var(--color-front);
    background: var(--color-back-lighter);
    margin: 0;


    .p-treeselect-filter-container {
      margin-right: 0.5rem;
      .p-treeselect-filter {
        padding-right: 2rem;
      }
      .p-treeselect-filter-icon {
        right: 1rem;
        color: var(--color-front-lighter);
      }
    }
    .p-treeselect-filter-container.p-treeselect-clearable-filter {
      .p-treeselect-filter {
        padding-right: 4rem;
      }
      .p-treeselect-filter-clear-icon {
        right: 3rem;
      }
    }
    .p-treeselect-close {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
  .p-treeselect-items-wrapper {
    .p-treeselect-empty-message {
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
    }
  }
}
p-treeselect.p-treeselect-clearable {
  .p-treeselect-label-container {
    padding-right: 2rem;
  }
  .p-treeselect-clear-icon {
    color: var(--color-front-lighter);
    right: 3rem;
  }
}
.p-button {
  color: $color_5;
  background: $color-primary;
  padding: 0.714rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

  font-weight: 500;
  &:enabled {
    &:hover {
      background: rgba(63, 81, 181, 0.92);
      color: $color_5;
      border-color: $border-color_4;
    }
    &:active {
      background: rgba(63, 81, 181, 0.68);
      color: $color_5;
      border-color: $border-color_4;
      background: rgba(63, 81, 181, 0.68);
    }
    &:focus {
      background: rgba(63, 81, 181, 0.76);
    }
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-button-icon-left {
    margin-right: 0.5rem;
  }
  .p-button-icon-right {
    margin-left: 0.5rem;
  }
  .p-button-icon-bottom {
    margin-top: 0.5rem;
  }
  .p-button-icon-top {
    margin-bottom: 0.5rem;
  }
  .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: $color_4;
    background-color: var(--color-back-lighter);
  }
  .p-ink {
    background-color: $background-color_21;
  }
  &:disabled {
    background-color: $background-color_14 !important;
    color: $color_23 var(--color-front-darker) !important;
    opacity: 1;
  }
  &:disabled.p-button-text {
    background-color: $background-color_5 !important;
    color: $color_23 var(--color-front-darker) !important;
  }
  &:disabled.p-button-outlined {
    background-color: $background-color_5 !important;
    color: $color_23 var(--color-front-darker) !important;
    border-color: $border-color_17 !important;
  }
}
.p-button.p-button-outlined {
  background-color: $background-color_5;
  color: $color_4;
  box-shadow: inset 0 0 0 1px;
  &:enabled {
    &:hover {
      background: rgba(63, 81, 181, 0.04);
      color: $color_4;
    }
    &:active {
      background: rgba(63, 81, 181, 0.16);
      color: $color_4;
      background: rgba(63, 81, 181, 0.16);
      box-shadow: inset 0 0 0 1px;
    }
    &:focus {
      background: rgba(63, 81, 181, 0.12);
      box-shadow: inset 0 0 0 1px;
    }
  }
  .p-ink {
    background-color: $background-color_22;
  }
}
.p-button.p-button-outlined.p-button-plain {
  color: var(--color-front-lighter);
   &:enabled {
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      color: var(--color-front-lighter);
    }
    &:active {
      background: rgba(0, 0, 0, 0.16);
      color: var(--color-front-lighter);
    }
  }
}
.p-button.p-button-text {
  background-color: $background-color_5;
  color: $color_4;
   &:enabled {
    &:hover {
      background: rgba(63, 81, 181, 0.04);
      color: $color_4;
     }
    &:active {
      background: rgba(63, 81, 181, 0.16);
      color: $color_4;
       background: rgba(63, 81, 181, 0.16);
    }
    &:focus {
      background: rgba(63, 81, 181, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_22;
  }
}
.p-button.p-button-text.p-button-plain {
  color: var(--color-front-lighter);
  &:enabled {
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      color: var(--color-front-lighter);
    }
    &:active {
      background: rgba(0, 0, 0, 0.16);
      color: var(--color-front-lighter);
    }
  }
}
.p-button.p-button-raised {
  border: 0 none;

}

.p-button.p-button-icon-only {

  padding: 0.714rem;
  min-width: auto;
  .p-button-icon-left {
    margin: 0;
  }
  .p-button-icon-right {
    margin: 0;
  }
}
.p-button.p-button-icon-only.p-button-rounded {

  height: 3rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.62475rem 0.875rem;
  .p-button-icon {
    font-size: 0.875rem;
  }
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.8925rem 1.25rem;
  .p-button-icon {
    font-size: 1.25rem;
  }
}
.p-button.p-button-loading-label-only {
  .p-button-label {
    margin-left: 0.5rem;
  }
  .p-button-loading-icon {
    margin-right: 0;
  }
}
.p-button.p-button-secondary {
  color: $color_5;
  background: #ff4081;
  &:enabled {
    &:hover {
      background: rgba(255, 64, 129, 0.92);
      color: $color_5;
      border-color: $border-color_4;
    }
    &:focus {
      box-shadow: none;
      background: rgba(255, 64, 129, 0.76);
    }
    &:active {
      background: rgba(255, 64, 129, 0.68);
      color: $color_5;
      border-color: $border-color_4;
      background: rgba(255, 64, 129, 0.68);
    }
  }
}
.p-buttonset.p-button-secondary {
  > .p-button {
    color: $color_5;
    background: #ff4081;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(255, 64, 129, 0.76);
      }
      &:active {
        background: rgba(255, 64, 129, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(255, 64, 129, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_6;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.04);
        color: $color_6;
      }
      &:active {
        background: rgba(255, 64, 129, 0.16);
        color: $color_6;
        background: rgba(255, 64, 129, 0.16);
      }
      &:focus {
        background: rgba(255, 64, 129, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_23;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_6;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.04);
        border-color: $border-color_4;
        color: $color_6;
      }
      &:active {
        background: rgba(255, 64, 129, 0.16);
        border-color: $border-color_4;
        color: $color_6;
        background: rgba(255, 64, 129, 0.16);
      }
      &:focus {
        background: rgba(255, 64, 129, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_23;
    }
  }
}
.p-splitbutton.p-button-secondary {
  > .p-button {
    color: $color_5;
    background: #ff4081;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.92);
        color: $color_5;
        border-color: var(--color-front);
      }
      &:focus {
        box-shadow: none;
        background: rgba(255, 64, 129, 0.76);
      }
      &:active {
        background: rgba(255, 64, 129, 0.68);
        color: $color_5;
        border-color: var(--color-front);
        background: rgba(255, 64, 129, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_6;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.04);
        color: $color_6;
      }
      &:active {
        background: rgba(255, 64, 129, 0.16);
        color: $color_6;
        background: rgba(255, 64, 129, 0.16);
      }
      &:focus {
        background: rgba(255, 64, 129, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_23;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_6;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.04);
        border-color: $border-color_4;
        color: $color_6;
      }
      &:active {
        background: rgba(255, 64, 129, 0.16);
        border-color: $border-color_4;
        color: $color_6;
        background: rgba(255, 64, 129, 0.16);
      }
      &:focus {
        background: rgba(255, 64, 129, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_23;
    }
  }
}
.p-button.p-button-secondary.p-button-outlined {
  background-color: $background-color_5;
  color: $color_6;
  &:enabled {
    &:hover {
      background: rgba(255, 64, 129, 0.04);
      color: $color_6;
    }
    &:active {
      background: rgba(255, 64, 129, 0.16);
      color: $color_6;
      background: rgba(255, 64, 129, 0.16);
    }
    &:focus {
      background: rgba(255, 64, 129, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_23;
  }
}
.p-button.p-button-secondary.p-button-text {
  background-color: $background-color_5;
  color: $color_6;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(255, 64, 129, 0.04);
      border-color: $border-color_4;
      color: $color_6;
    }
    &:active {
      background: rgba(255, 64, 129, 0.16);
      border-color: $border-color_4;
      color: $color_6;
      background: rgba(255, 64, 129, 0.16);
    }
    &:focus {
      background: rgba(255, 64, 129, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_23;
  }
}
.p-button.p-button-info {
  color: $color_5;
  background: #2196f3;
  &:enabled {
    &:hover {
      background: rgba(33, 150, 243, 0.92);
      color: $color_5;
      border-color: $border-color_4;
    }
    &:focus {
      box-shadow: none;
      background: rgba(33, 150, 243, 0.76);
    }
    &:active {
      background: rgba(33, 150, 243, 0.68);
      color: $color_5;
      border-color: $border-color_4;
      background: rgba(33, 150, 243, 0.68);
    }
  }
}
.p-buttonset.p-button-info {
  > .p-button {
    color: $color_5;
    background: #2196f3;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(33, 150, 243, 0.76);
      }
      &:active {
        background: rgba(33, 150, 243, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(33, 150, 243, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_7;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.04);
        color: $color_7;
      }
      &:active {
        background: rgba(33, 150, 243, 0.16);
        color: $color_7;
        background: rgba(33, 150, 243, 0.16);
      }
      &:focus {
        background: rgba(33, 150, 243, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_24;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_7;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.04);
        border-color: $border-color_4;
        color: $color_7;
      }
      &:active {
        background: rgba(33, 150, 243, 0.16);
        border-color: $border-color_4;
        color: $color_7;
        background: rgba(33, 150, 243, 0.16);
      }
      &:focus {
        background: rgba(33, 150, 243, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_24;
    }
  }
}
.p-splitbutton.p-button-info {
  > .p-button {
    color: $color_5;
    background: #2196f3;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(33, 150, 243, 0.76);
      }
      &:active {
        background: rgba(33, 150, 243, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(33, 150, 243, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_7;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.04);
        color: $color_7;
      }
      &:active {
        background: rgba(33, 150, 243, 0.16);
        color: $color_7;
        background: rgba(33, 150, 243, 0.16);
      }
      &:focus {
        background: rgba(33, 150, 243, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_24;
    }
    > .p-button {
      &:enabled {
        &:focus {
          background: rgba(33, 150, 243, 0.12);
        }
        &:active {
          background: rgba(33, 150, 243, 0.16);
        }
      }
      &:not(button) {
        &:not(a) {
          &:not(.p-disabled) {
            &:focus {
              background: rgba(33, 150, 243, 0.12);
            }
            &:active {
              background: rgba(33, 150, 243, 0.16);
            }
          }
        }
      }
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_7;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.04);
        border-color: $border-color_4;
        color: $color_7;
      }
      &:active {
        background: rgba(33, 150, 243, 0.16);
        border-color: $border-color_4;
        color: $color_7;
        background: rgba(33, 150, 243, 0.16);
      }
      &:focus {
        background: rgba(33, 150, 243, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_24;
    }
    > .p-button {
      &:enabled {
        &:focus {
          background: rgba(33, 150, 243, 0.12);
        }
        &:active {
          background: rgba(33, 150, 243, 0.16);
        }
      }
      &:not(button) {
        &:not(a) {
          &:not(.p-disabled) {
            &:focus {
              background: rgba(33, 150, 243, 0.12);
            }
            &:active {
              background: rgba(33, 150, 243, 0.16);
            }
          }
        }
      }
    }
  }
}
.p-button.p-button-info.p-button-outlined {
  background-color: $background-color_5;
  color: $color_7;
  &:enabled {
    &:hover {
      background: rgba(33, 150, 243, 0.04);
      color: $color_7;
    }
    &:active {
      background: rgba(33, 150, 243, 0.16);
      color: $color_7;
      background: rgba(33, 150, 243, 0.16);
    }
    &:focus {
      background: rgba(33, 150, 243, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_24;
  }
}
.p-button.p-button-info.p-button-text {
  background-color: $background-color_5;
  color: $color_7;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(33, 150, 243, 0.04);
      border-color: $border-color_4;
      color: $color_7;
    }
    &:active {
      background: rgba(33, 150, 243, 0.16);
      border-color: $border-color_4;
      color: $color_7;
      background: rgba(33, 150, 243, 0.16);
    }
    &:focus {
      background: rgba(33, 150, 243, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_24;
  }
}
.p-button.p-button-success {
  color: $color_5;
  background: #689f38;
  &:enabled {
    &:hover {
      background: rgba(104, 159, 56, 0.92);
      color: $color_5;
      border-color: $border-color_4;
    }
    &:focus {
      box-shadow: none;
      background: rgba(104, 159, 56, 0.76);
    }
    &:active {
      background: rgba(104, 159, 56, 0.68);
      color: $color_5;
      border-color: $border-color_4;
      background: rgba(104, 159, 56, 0.68);
    }
  }
}
.p-buttonset.p-button-success {
  > .p-button {
    color: $color_5;
    background: #689f38;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(104, 159, 56, 0.76);
      }
      &:active {
        background: rgba(104, 159, 56, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(104, 159, 56, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_8;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.04);
        color: $color_8;
      }
      &:active {
        background: rgba(104, 159, 56, 0.16);
        color: $color_8;
        background: rgba(104, 159, 56, 0.16);
      }
      &:focus {
        background: rgba(104, 159, 56, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_25;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_8;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.04);
        border-color: $border-color_4;
        color: $color_8;
      }
      &:active {
        background: rgba(104, 159, 56, 0.16);
        border-color: $border-color_4;
        color: $color_8;
        background: rgba(104, 159, 56, 0.16);
      }
      &:focus {
        background: rgba(104, 159, 56, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_25;
    }
  }
}
.p-splitbutton.p-button-success {
  > .p-button {
    color: $color_5;
    background: #689f38;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(104, 159, 56, 0.76);
      }
      &:active {
        background: rgba(104, 159, 56, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(104, 159, 56, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_8;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.04);
        color: $color_8;
      }
      &:active {
        background: rgba(104, 159, 56, 0.16);
        color: $color_8;
        background: rgba(104, 159, 56, 0.16);
      }
      &:focus {
        background: rgba(104, 159, 56, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_25;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_8;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.04);
        border-color: $border-color_4;
        color: $color_8;
      }
      &:active {
        background: rgba(104, 159, 56, 0.16);
        border-color: $border-color_4;
        color: $color_8;
        background: rgba(104, 159, 56, 0.16);
      }
      &:focus {
        background: rgba(104, 159, 56, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_25;
    }
  }
}
.p-button.p-button-success.p-button-outlined {
  background-color: $background-color_5;
  color: $color_8;
  &:enabled {
    &:hover {
      background: rgba(104, 159, 56, 0.04);
      color: $color_8;
    }
    &:active {
      background: rgba(104, 159, 56, 0.16);
      color: $color_8;
      background: rgba(104, 159, 56, 0.16);
    }
    &:focus {
      background: rgba(104, 159, 56, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_25;
  }
}
.p-button.p-button-success.p-button-text {
  background-color: $background-color_5;
  color: $color_8;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(104, 159, 56, 0.04);
      border-color: $border-color_4;
      color: $color_8;
    }
    &:active {
      background: rgba(104, 159, 56, 0.16);
      border-color: $border-color_4;
      color: $color_8;
      background: rgba(104, 159, 56, 0.16);
    }
    &:focus {
      background: rgba(104, 159, 56, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_25;
  }
}
.p-button.p-button-warning {
  color: $color_9;
  background: #fbc02d;
  &:enabled {
    &:hover {
      background: rgba(251, 192, 45, 0.92);
      color: $color_9;
      border-color: $border-color_4;
    }
    &:focus {
      box-shadow: none;
      background: rgba(251, 192, 45, 0.76);
    }
    &:active {
      background: rgba(251, 192, 45, 0.68);
      color: $color_9;
      border-color: $border-color_4;
      background: rgba(251, 192, 45, 0.68);
    }
  }
}
.p-buttonset.p-button-warning {
  > .p-button {
    color: $color_9;
    background: #fbc02d;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.92);
        color: $color_9;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(251, 192, 45, 0.76);
      }
      &:active {
        background: rgba(251, 192, 45, 0.68);
        color: $color_9;
        border-color: $border-color_4;
        background: rgba(251, 192, 45, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_10;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        color: $color_10;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        color: $color_10;
        background: rgba(251, 192, 45, 0.16);
      }
      &:focus {
        background: rgba(251, 192, 45, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_26;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_10;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        border-color: $border-color_4;
        color: $color_10;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        border-color: $border-color_4;
        color: $color_10;
        background: rgba(251, 192, 45, 0.16);
      }
      &:focus {
        background: rgba(251, 192, 45, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_26;
    }
  }
}
.p-splitbutton.p-button-warning {
  > .p-button {
    color: $color_9;
    background: #fbc02d;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.92);
        color: $color_9;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(251, 192, 45, 0.76);
      }
      &:active {
        background: rgba(251, 192, 45, 0.68);
        color: $color_9;
        border-color: $border-color_4;
        background: rgba(251, 192, 45, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_10;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        color: $color_10;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        color: $color_10;
        background: rgba(251, 192, 45, 0.16);
      }
      &:focus {
        background: rgba(251, 192, 45, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_26;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_10;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        border-color: $border-color_4;
        color: $color_10;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        border-color: $border-color_4;
        color: $color_10;
        background: rgba(251, 192, 45, 0.16);
      }
      &:focus {
        background: rgba(251, 192, 45, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_26;
    }
  }
}
.p-button.p-button-warning.p-button-outlined {
  background-color: $background-color_5;
  color: $color_10;
  &:enabled {
    &:hover {
      background: rgba(251, 192, 45, 0.04);
      color: $color_10;
    }
    &:active {
      background: rgba(251, 192, 45, 0.16);
      color: $color_10;
      background: rgba(251, 192, 45, 0.16);
    }
    &:focus {
      background: rgba(251, 192, 45, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_26;
  }
}
.p-button.p-button-warning.p-button-text {
  background-color: $background-color_5;
  color: $color_10;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(251, 192, 45, 0.04);
      border-color: $border-color_4;
      color: $color_10;
    }
    &:active {
      background: rgba(251, 192, 45, 0.16);
      border-color: $border-color_4;
      color: $color_10;
      background: rgba(251, 192, 45, 0.16);
    }
    &:focus {
      background: rgba(251, 192, 45, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_26;
  }
}
.p-button.p-button-help {
  color: $color_5;
  background: #9c27b0;
  &:enabled {
    &:hover {
      background: rgba(156, 39, 176, 0.92);
      color: $color_5;
      border-color: $border-color_4;
    }
    &:focus {
      box-shadow: none;
      background: rgba(156, 39, 176, 0.76);
    }
    &:active {
      background: rgba(156, 39, 176, 0.68);
      color: $color_5;
      border-color: $border-color_4;
      background: rgba(156, 39, 176, 0.68);
    }
  }
}
.p-buttonset.p-button-help {
  > .p-button {
    color: $color_5;
    background: #9c27b0;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(156, 39, 176, 0.76);
      }
      &:active {
        background: rgba(156, 39, 176, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(156, 39, 176, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_11;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        color: $color_11;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        color: $color_11;
        background: rgba(156, 39, 176, 0.16);
      }
      &:focus {
        background: rgba(156, 39, 176, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_27;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_11;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        border-color: $border-color_4;
        color: $color_11;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        border-color: $border-color_4;
        color: $color_11;
        background: rgba(156, 39, 176, 0.16);
      }
      &:focus {
        background: rgba(156, 39, 176, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_27;
    }
  }
}
.p-splitbutton.p-button-help {
  > .p-button {
    color: $color_5;
    background: #9c27b0;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(156, 39, 176, 0.76);
      }
      &:active {
        background: rgba(156, 39, 176, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(156, 39, 176, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_11;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        color: $color_11;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        color: $color_11;
        background: rgba(156, 39, 176, 0.16);
      }
      &:focus {
        background: rgba(156, 39, 176, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_27;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_11;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        border-color: $border-color_4;
        color: $color_11;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        border-color: $border-color_4;
        color: $color_11;
        background: rgba(156, 39, 176, 0.16);
      }
      &:focus {
        background: rgba(156, 39, 176, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_27;
    }
  }
}
.p-button.p-button-help.p-button-outlined {
  background-color: $background-color_5;
  color: $color_11;
  &:enabled {
    &:hover {
      background: rgba(156, 39, 176, 0.04);
      color: $color_11;
    }
    &:active {
      background: rgba(156, 39, 176, 0.16);
      color: $color_11;
      background: rgba(156, 39, 176, 0.16);
    }
    &:focus {
      background: rgba(156, 39, 176, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_27;
  }
}
.p-button.p-button-help.p-button-text {
  background-color: $background-color_5;
  color: $color_11;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(156, 39, 176, 0.04);
      border-color: $border-color_4;
      color: $color_11;
    }
    &:active {
      background: rgba(156, 39, 176, 0.16);
      border-color: $border-color_4;
      color: $color_11;
      background: rgba(156, 39, 176, 0.16);
    }
    &:focus {
      background: rgba(156, 39, 176, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_27;
  }
}
.p-button.p-button-danger {
  color: $color_5;
  background: #d32f2f;
  &:enabled {
    &:hover {
      background: rgba(211, 47, 47, 0.92);
      color: $color_5;
      border-color: $border-color_4;
    }
    &:focus {
      box-shadow: none;
      background: rgba(211, 47, 47, 0.76);
    }
    &:active {
      background: rgba(211, 47, 47, 0.68);
      color: $color_5;
      border-color: $border-color_4;
      background: rgba(211, 47, 47, 0.68);
    }
  }
}
.p-buttonset.p-button-danger {
  > .p-button {
    color: $color_5;
    background: #d32f2f;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(211, 47, 47, 0.76);
      }
      &:active {
        background: rgba(211, 47, 47, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(211, 47, 47, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_12;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.04);
        color: $color_12;
      }
      &:active {
        background: rgba(211, 47, 47, 0.16);
        color: $color_12;
        background: rgba(211, 47, 47, 0.16);
      }
      &:focus {
        background: rgba(211, 47, 47, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_28;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_12;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.04);
        border-color: $border-color_4;
        color: $color_12;
      }
      &:active {
        background: rgba(211, 47, 47, 0.16);
        border-color: $border-color_4;
        color: $color_12;
        background: rgba(211, 47, 47, 0.16);
      }
      &:focus {
        background: rgba(211, 47, 47, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_28;
    }
  }
}
.p-splitbutton.p-button-danger {
  > .p-button {
    color: $color_5;
    background: #d32f2f;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.92);
        color: $color_5;
        border-color: $border-color_4;
      }
      &:focus {
        box-shadow: none;
        background: rgba(211, 47, 47, 0.76);
      }
      &:active {
        background: rgba(211, 47, 47, 0.68);
        color: $color_5;
        border-color: $border-color_4;
        background: rgba(211, 47, 47, 0.68);
      }
    }
  }
  > .p-button.p-button-outlined {
    background-color: $background-color_5;
    color: $color_12;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.04);
        color: $color_12;
      }
      &:active {
        background: rgba(211, 47, 47, 0.16);
        color: $color_12;
        background: rgba(211, 47, 47, 0.16);
      }
      &:focus {
        background: rgba(211, 47, 47, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_28;
    }
  }
  > .p-button.p-button-text {
    background-color: $background-color_5;
    color: $color_12;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.04);
        border-color: $border-color_4;
        color: $color_12;
      }
      &:active {
        background: rgba(211, 47, 47, 0.16);
        border-color: $border-color_4;
        color: $color_12;
        background: rgba(211, 47, 47, 0.16);
      }
      &:focus {
        background: rgba(211, 47, 47, 0.12);
      }
    }
    .p-ink {
      background-color: $background-color_28;
    }
  }
}
.p-button.p-button-danger.p-button-outlined {
  background-color: $background-color_5;
  color: $color_12;
  &:enabled {
    &:hover {
      background: rgba(211, 47, 47, 0.04);
      color: $color_12;
    }
    &:active {
      background: rgba(211, 47, 47, 0.16);
      color: $color_12;
      background: rgba(211, 47, 47, 0.16);
    }
    &:focus {
      background: rgba(211, 47, 47, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_28;
  }
}
.p-button.p-button-danger.p-button-text {
  background-color: $background-color_5;
  color: $color_12;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(211, 47, 47, 0.04);
      border-color: $border-color_4;
      color: $color_12;
    }
    &:active {
      background: rgba(211, 47, 47, 0.16);
      border-color: $border-color_4;
      color: $color_12;
      background: rgba(211, 47, 47, 0.16);
    }
    &:focus {
      background: rgba(211, 47, 47, 0.12);
    }
  }
  .p-ink {
    background-color: $background-color_28;
  }
}
.p-button.p-button-link {
  color: var(--color-front);
  background: transparent;
  border: transparent;
  &:enabled {
    &:hover {
      background: transparent;
      color: $color_4;
      border-color: $border-color_4;
      .p-button-label {
        text-decoration: underline;
      }
    }
    &:focus {
      background: transparent;
      box-shadow: none;
      border-color: $border-color_4;
    }
    &:active {
      background: transparent;
      color: $color_4;
      border-color: $border-color_4;
    }
  }
}


.p-splitbutton.p-button-outlined {
  > .p-button {
    background-color: $background-color_5;
    color: $color_4;
    box-shadow: inset 0 0 0 1px;
    &:enabled {
      &:hover {
        background: rgba(63, 81, 181, 0.04);
        color: $color_4;
      }
      &:active {
        background: rgba(63, 81, 181, 0.16);
        color: $color_4;
        background: rgba(63, 81, 181, 0.16);
        box-shadow: inset 0 0 0 1px;
      }
      &:focus {
        background: rgba(63, 81, 181, 0.12);
        box-shadow: inset 0 0 0 1px;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(63, 81, 181, 0.04);
            color: $color_4;
          }
          &:active {
            background: rgba(63, 81, 181, 0.16);
            color: $color_4;
            background: rgba(63, 81, 181, 0.16);
          }
          &:focus {
            background: rgba(63, 81, 181, 0.12);
          }
        }
      }
    }
  }
  > .p-button.p-splitbutton-menubutton {
    margin-left: -1px;
    width: calc(3rem + 1px);
  }
}
.p-splitbutton.p-button-outlined.p-button-plain {
  > .p-button {
    color: var(--color-front-lighter);
    border-color: $border-color_12;
    &:enabled {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front-lighter);
      }
      &:active {
        background: rgba(0, 0, 0, 0.16);
        color: var(--color-front-lighter);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            color: var(--color-front-lighter);
          }
          &:active {
            background: rgba(0, 0, 0, 0.16);
            color: var(--color-front-lighter);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-text {
  > .p-button {
    background-color: $background-color_5;
    color: $color_4;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(63, 81, 181, 0.04);
        color: $color_4;
        border-color: $border-color_4;
      }
      &:active {
        background: rgba(63, 81, 181, 0.16);
        color: $color_4;
        border-color: $border-color_4;
        background: rgba(63, 81, 181, 0.16);
      }
      &:focus {
        background: rgba(63, 81, 181, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(63, 81, 181, 0.04);
            color: $color_4;
            border-color: $border-color_4;
          }
          &:active {
            background: rgba(63, 81, 181, 0.16);
            color: $color_4;
            border-color: $border-color_4;
            background: rgba(63, 81, 181, 0.16);
          }
          &:focus {
            background: rgba(63, 81, 181, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-text.p-button-plain {
  > .p-button {
    color: var(--color-front-lighter);
    &:enabled {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front-lighter);
      }
      &:active {
        background: rgba(0, 0, 0, 0.16);
        color: var(--color-front-lighter);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            color: var(--color-front-lighter);
          }
          &:active {
            background: rgba(0, 0, 0, 0.16);
            color: var(--color-front-lighter);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-raised {
  border: 0px none;
  > .p-button {
    &:enabled {
      &:focus {
        border: 4px solid var(--color-front);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:focus {
            border: 4px solid var(--color-front);
          }
        }
      }
    }
  }
}

.p-splitbutton.p-button-sm {
  > .p-button {
    font-size: 0.875rem;
    padding: 0.62475rem 0.875rem;
    .p-button-icon {
      font-size: 0.875rem;
    }
  }
}
.p-splitbutton.p-button-lg {
  > .p-button {
    font-size: 1.25rem;
    padding: 0.8925rem 1.25rem;
    .p-button-icon {
      font-size: 1.25rem;
    }
  }
}
.p-splitbutton.p-button-secondary.p-button-outlined {
  > .p-button {
    background-color: $background-color_5;
    color: $color_6;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.04);
        color: $color_6;
      }
      &:active {
        background: rgba(255, 64, 129, 0.16);
        color: $color_6;
        background: rgba(255, 64, 129, 0.16);
      }
      &:focus {
        background: rgba(255, 64, 129, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(255, 64, 129, 0.04);
            color: $color_6;
          }
          &:active {
            background: rgba(255, 64, 129, 0.16);
            color: $color_6;
            background: rgba(255, 64, 129, 0.16);
          }
          &:focus {
            background: rgba(255, 64, 129, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-secondary.p-button-text {
  > .p-button {
    background-color: $background-color_5;
    color: $color_6;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(255, 64, 129, 0.04);
        border-color: $border-color_4;
        color: $color_6;
      }
      &:active {
        background: rgba(255, 64, 129, 0.16);
        border-color: $border-color_4;
        color: $color_6;
        background: rgba(255, 64, 129, 0.16);
      }
      &:focus {
        background: rgba(255, 64, 129, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(255, 64, 129, 0.04);
            border-color: $border-color_4;
            color: $color_6;
          }
          &:active {
            background: rgba(255, 64, 129, 0.16);
            border-color: $border-color_4;
            color: $color_6;
            background: rgba(255, 64, 129, 0.16);
          }
          &:focus {
            background: rgba(255, 64, 129, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-info.p-button-outlined {
  > .p-button {
    background-color: $background-color_5;
    color: $color_7;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.04);
        color: $color_7;
      }
      &:active {
        background: rgba(33, 150, 243, 0.16);
        color: $color_7;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(33, 150, 243, 0.04);
            color: $color_7;
          }
          &:active {
            background: rgba(33, 150, 243, 0.16);
            color: $color_7;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-info.p-button-text {
  > .p-button {
    background-color: $background-color_5;
    color: $color_7;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(33, 150, 243, 0.04);
        border-color: $border-color_4;
        color: $color_7;
      }
      &:active {
        background: rgba(33, 150, 243, 0.16);
        border-color: $border-color_4;
        color: $color_7;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(33, 150, 243, 0.04);
            border-color: $border-color_4;
            color: $color_7;
          }
          &:active {
            background: rgba(33, 150, 243, 0.16);
            border-color: $border-color_4;
            color: $color_7;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-success.p-button-outlined {
  > .p-button {
    background-color: $background-color_5;
    color: $color_8;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.04);
        color: $color_8;
      }
      &:active {
        background: rgba(104, 159, 56, 0.16);
        color: $color_8;
        background: rgba(104, 159, 56, 0.16);
      }
      &:focus {
        background: rgba(104, 159, 56, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(104, 159, 56, 0.04);
            color: $color_8;
          }
          &:active {
            background: rgba(104, 159, 56, 0.16);
            color: $color_8;
            background: rgba(104, 159, 56, 0.16);
          }
          &:focus {
            background: rgba(104, 159, 56, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-success.p-button-text {
  > .p-button {
    background-color: $background-color_5;
    color: $color_8;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(104, 159, 56, 0.04);
        border-color: $border-color_4;
        color: $color_8;
      }
      &:active {
        background: rgba(104, 159, 56, 0.16);
        border-color: $border-color_4;
        color: $color_8;
        background: rgba(104, 159, 56, 0.16);
      }
      &:focus {
        background: rgba(104, 159, 56, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(104, 159, 56, 0.04);
            border-color: $border-color_4;
            color: $color_8;
          }
          &:active {
            background: rgba(104, 159, 56, 0.16);
            border-color: $border-color_4;
            color: $color_8;
            background: rgba(104, 159, 56, 0.16);
          }
          &:focus {
            background: rgba(104, 159, 56, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-warning.p-button-outlined {
  > .p-button {
    background-color: $background-color_5;
    color: $color_10;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        color: $color_10;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        color: $color_10;
        background: rgba(251, 192, 45, 0.16);
      }
      &:focus {
        background: rgba(251, 192, 45, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(251, 192, 45, 0.04);
            color: $color_10;
          }
          &:active {
            background: rgba(251, 192, 45, 0.16);
            color: $color_10;
            background: rgba(251, 192, 45, 0.16);
          }
          &:focus {
            background: rgba(251, 192, 45, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-warning.p-button-text {
  > .p-button {
    background-color: $background-color_5;
    color: $color_10;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(251, 192, 45, 0.04);
        border-color: $border-color_4;
        color: $color_10;
      }
      &:active {
        background: rgba(251, 192, 45, 0.16);
        border-color: $border-color_4;
        color: $color_10;
        background: rgba(251, 192, 45, 0.16);
      }
      &:focus {
        background: rgba(251, 192, 45, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(251, 192, 45, 0.04);
            border-color: $border-color_4;
            color: $color_10;
          }
          &:active {
            background: rgba(251, 192, 45, 0.16);
            border-color: $border-color_4;
            color: $color_10;
            background: rgba(251, 192, 45, 0.16);
          }
          &:focus {
            background: rgba(251, 192, 45, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-help.p-button-outlined {
  > .p-button {
    background-color: $background-color_5;
    color: $color_11;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        color: $color_11;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        color: $color_11;
        background: rgba(156, 39, 176, 0.16);
      }
      &:focus {
        background: rgba(156, 39, 176, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(156, 39, 176, 0.04);
            color: $color_11;
          }
          &:active {
            background: rgba(156, 39, 176, 0.16);
            color: $color_11;
            background: rgba(156, 39, 176, 0.16);
          }
          &:focus {
            background: rgba(156, 39, 176, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-help.p-button-text {
  > .p-button {
    background-color: $background-color_5;
    color: $color_11;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(156, 39, 176, 0.04);
        border-color: $border-color_4;
        color: $color_11;
      }
      &:active {
        background: rgba(156, 39, 176, 0.16);
        border-color: $border-color_4;
        color: $color_11;
        background: rgba(156, 39, 176, 0.16);
      }
      &:focus {
        background: rgba(156, 39, 176, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(156, 39, 176, 0.04);
            border-color: $border-color_4;
            color: $color_11;
          }
          &:active {
            background: rgba(156, 39, 176, 0.16);
            border-color: $border-color_4;
            color: $color_11;
            background: rgba(156, 39, 176, 0.16);
          }
          &:focus {
            background: rgba(156, 39, 176, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-danger.p-button-outlined {
  > .p-button {
    background-color: $background-color_5;
    color: $color_12;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.04);
        color: $color_12;
      }
      &:active {
        background: rgba(211, 47, 47, 0.16);
        color: $color_12;
        background: rgba(211, 47, 47, 0.16);
      }
      &:focus {
        background: rgba(211, 47, 47, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(211, 47, 47, 0.04);
            color: $color_12;
          }
          &:active {
            background: rgba(211, 47, 47, 0.16);
            color: $color_12;
            background: rgba(211, 47, 47, 0.16);
          }
          &:focus {
            background: rgba(211, 47, 47, 0.12);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-danger.p-button-text {
  > .p-button {
    background-color: $background-color_5;
    color: $color_12;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(211, 47, 47, 0.04);
        border-color: $border-color_4;
        color: $color_12;
      }
      &:active {
        background: rgba(211, 47, 47, 0.16);
        border-color: $border-color_4;
        color: $color_12;
        background: rgba(211, 47, 47, 0.16);
      }
      &:focus {
        background: rgba(211, 47, 47, 0.12);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(211, 47, 47, 0.04);
            border-color: $border-color_4;
            color: $color_12;
          }
          &:active {
            background: rgba(211, 47, 47, 0.16);
            border-color: $border-color_4;
            color: $color_12;
            background: rgba(211, 47, 47, 0.16);
          }
          &:focus {
            background: rgba(211, 47, 47, 0.12);
          }
        }
      }
    }
  }
}
.p-carousel {
  .p-carousel-content {
    .p-carousel-prev {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin: 0.5rem;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    .p-carousel-next {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin: 0.5rem;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
  .p-carousel-indicators {
    padding: 1rem;
    .p-carousel-indicator {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      button {
        background-color: $background-color_3;
        width: 2rem;
        height: 0.5rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

        &:hover {
          background: #ececec;
        }
      }
    }
    .p-carousel-indicator.p-highlight {
      button {
        background: rgba(63, 81, 181, 0.12);
        color: $color_4;
        background: #ff4081;
        color: $color_5;
      }
    }
  }
}
.p-datatable {
  .p-paginator-top {
    border-width: 0 0 1px 0;

  }
  .p-paginator-bottom {
    border-width: 0 0 1px 0;

  }
  .p-datatable-header {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
  }
  .p-datatable-footer {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
  }
  .p-datatable-thead {
    > tr {
      > th {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e4e4e4;
        border-width: 0 0 1px 0;
        font-weight: 500;
        color: var(--color-front);
        background: var(--color-back-lighter);
        transition: none;
      }
    }
  }
  .p-datatable-tfoot {
    > tr {
      > td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e4e4e4;
        border-width: 0 0 1px 0;
        font-weight: 500;
        color: var(--color-front);
        background: var(--color-back-lighter);
      }
    }
  }
  .p-sortable-column {
    .p-sortable-column-icon {
      color: var(--color-front-lighter);
      margin-left: 0.5rem;
    }
    .p-sortable-column-badge {

      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
      margin-left: 0.5rem;
    }
    &:not(.p-highlight) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front);
        .p-sortable-column-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      box-shadow: none;
      outline: 0 none;
      background-color: $background-color_29;
    }
    outline: 0 none;
  }
  .p-sortable-column.p-highlight {
    background: var(--color-back-lighter);
    color: var(--color-front);
    .p-sortable-column-icon {
      color: var(--color-front);
    }
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      color: var(--color-front);
      .p-sortable-column-icon {
        color: var(--color-front);
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      background: var(--color-back-lighter);
      color: var(--color-front);
      transition: none;
      outline: 0 none;
      > td {
        text-align: left;
        border: 1px solid #e4e4e4;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        .p-row-toggler {
          width: 2.5rem;
          height: 2.5rem;
          color: var(--color-front-lighter);
          background: transparent;

          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: var(--color-front-lighter);
              border-color: $border-color_4;
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
        .p-row-editor-init {
          width: 2.5rem;
          height: 2.5rem;
          color: var(--color-front-lighter);
          background: transparent;

          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: var(--color-front-lighter);
              border-color: $border-color_4;
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
        .p-row-editor-save {
          width: 2.5rem;
          height: 2.5rem;
          color: var(--color-front-lighter);
          background: transparent;

          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin-right: 0.5rem;
          &:enabled {
            &:hover {
              color: var(--color-front-lighter);
              border-color: $border-color_4;
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
        .p-row-editor-cancel {
          width: 2.5rem;
          height: 2.5rem;
          color: var(--color-front-lighter);
          background: transparent;

          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: var(--color-front-lighter);
              border-color: $border-color_4;
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
      }
      &:focus {
        outline: 0.15rem solid transparent;
        outline-offset: 0.15rem;
      }
      &:not(.p-highlight) {
        &:focus {
          background-color: $background-color_29;
        }
      }
    }
    > tr.p-highlight {
      background: rgba(63, 81, 181, 0.12);
      color: $color_4;
    }
    > tr.p-datatable-dragpoint-top {
      > td {
        box-shadow: inset 0 2px 0 0 rgba(63, 81, 181, 0.12);
        box-shadow: inset 0 2px 0 0 $color-primary;
      }
    }
    > tr.p-datatable-dragpoint-bottom {
      > td {
        box-shadow: inset 0 -2px 0 0 rgba(63, 81, 181, 0.12);
        box-shadow: inset 0 -2px 0 0 $color-primary;
      }
    }
  }
  .p-column-resizer-helper {
    background: $color-primary;
  }
  .p-datatable-scrollable-header {
    background: var(--color-back-lighter);
  }
  .p-datatable-scrollable-footer {
    background: var(--color-back-lighter);
  }
  .p-datatable-loading-icon {
    font-size: 2rem;
  }
}
.p-datatable.p-datatable-hoverable-rows {
  .p-datatable-tbody {
    > tr {
      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          color: var(--color-front);
        }
      }
    }
  }
}
.p-datatable.p-datatable-scrollable {
  > .p-datatable-wrapper {
    > .p-datatable-table {
      > .p-datatable-thead {
        background-color: var(--color-back-lighter);
      }
      > .p-datatable-tfoot {
        background-color: var(--color-back-lighter);
      }
    }
  }
}
.p-datatable.p-datatable-gridlines {
  .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable-thead {
    > tr {
      > th {
        border-width: 1px 1px 1px 1px;
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      > td {
        border-width: 1px;
      }
    }
  }
  .p-datatable-tfoot {
    > tr {
      > td {
        border-width: 1px;
      }
    }
  }
}
.p-datatable.p-datatable-striped {
  .p-datatable-tbody {
    > tr {
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.02);
      }
      &:nth-child(even).p-highlight {
        background: rgba(63, 81, 181, 0.12);
        color: $color_4;
        .p-row-toggler {
          color: $color_4;
          &:hover {
            color: $color_4;
          }
        }
      }
    }
  }
}
.p-datatable.p-datatable-sm {
  .p-datatable-header {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable-thead {
    > tr {
      > th {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-tfoot {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-footer {
    padding: 0.5rem 0.5rem;
  }
}
.p-datatable.p-datatable-lg {
  .p-datatable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable-thead {
    > tr {
      > th {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-tbody {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-tfoot {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-footer {
    padding: 1.25rem 1.25rem;
  }
}
.p-dataview {
  .p-paginator-top {
    border-width: 0 0 1px 0;

  }
  .p-paginator-bottom {
    border-width: 0 0 1px 0;

  }
  .p-dataview-header {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
  }
  .p-dataview-content {
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 1rem 0;
  }
  .p-dataview-footer {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;


  }
  .p-dataview-loading-icon {
    font-size: 2rem;
  }
  .p-dataview-emptymessage {
    padding: 1rem;
  }
}
.p-dataview.p-dataview-list {
  .p-dataview-content {
    > .p-grid {
      > div {
        border: solid rgba(0, 0, 0, 0.12);
        border-width: 0 0 1px 0;
      }
    }
  }
}
.p-column-filter-row {
  .p-column-filter-menu-button {
    margin-left: 0.5rem;
  }
  .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
}
.p-column-filter-menu-button {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--color-front-lighter);
  background: transparent;

  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  &:hover {
    color: var(--color-front-lighter);
    border-color: $border-color_4;
    background: rgba(0, 0, 0, 0.04);
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-open {
  background: rgba(0, 0, 0, 0.04);
  color: var(--color-front-lighter);
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    color: var(--color-front-lighter);
  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
  background: rgba(63, 81, 181, 0.12);
  color: $color_4;
  &:hover {
    background: rgba(63, 81, 181, 0.12);
    color: $color_4;
  }
}
.p-column-filter-clear-button {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--color-front-lighter);
  background: transparent;

  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  &:hover {
    color: var(--color-front-lighter);
    border-color: $border-color_4;
    background: rgba(0, 0, 0, 0.04);
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
}
.p-column-filter-overlay {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  min-width: 12.5rem;
  .p-column-filter-row-items {
    padding: 0;
    .p-column-filter-row-item {
      margin: 0;
      padding: 1rem 1rem;
      color: var(--color-front);
      background: transparent;
      transition: none;

      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: var(--color-front);
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    .p-column-filter-row-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
    .p-column-filter-separator {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      margin: 0.5rem 0;
    }
  }
}
.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: var(--color-front);
    background: var(--color-back-lighter);
    margin: 0;


  }
  .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .p-column-filter-matchmode-dropdown {
      margin-bottom: 0.5rem;
    }
    .p-column-filter-remove-button {
      margin-top: 0.5rem;
    }
    &:last-child {
      border-bottom: 0 none;
    }
  }
  .p-column-filter-add-rule {
    padding: 1rem 1rem;
  }
  .p-column-filter-buttonbar {
    padding: 1rem;
  }
}
.fc.fc-unthemed {
  .fc-view-container {
    th {
      background: var(--color-back-lighter);
      border: 1px solid #e0e0e0;
      color: var(--color-front);
    }
    td.fc-widget-content {
      background: var(--color-back-lighter);
      border: 1px solid #e0e0e0;
      color: var(--color-front);
    }
    td.fc-head-container {
      border: 1px solid #e0e0e0;
    }
    .fc-row {
      border-right: 1px solid #e0e0e0;
    }
    .fc-event {
      background: rgba(63, 81, 181, 0.12);
      border: 1px solid rgba(63, 81, 181, 0.12);
      color: $color_4;
    }
    .fc-divider {
      background: var(--color-back-lighter);
      border: 1px solid #e0e0e0;
    }
  }
  .fc-toolbar {
    .fc-button {
      color: $color_5;
      background: $color-primary;
      font-size: 1rem;
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

      display: flex;
      align-items: center;
      &:enabled {
        &:hover {
          background: rgba(63, 81, 181, 0.92);
          color: $color_5;
          border-color: $border-color_4;
        }
        &:active {
          background: rgba(63, 81, 181, 0.68);
          color: $color_5;
          border-color: $border-color_4;
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
      }
      .fc-icon-chevron-left {
        font-family: $font-family_2 !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e900";
        }
      }
      .fc-icon-chevron-right {
        font-family: $font-family_2 !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e901";
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        background: rgba(63, 81, 181, 0.76);
      }
      &:active {
        background: rgba(63, 81, 181, 0.68);
      }
    }
    .fc-button.fc-dayGridMonth-button {
      background: var(--color-back-lighter);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: var(--color-front);
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
      &:hover {
        background: #f6f6f6;
        border-color: $border-color_8;
        color: var(--color-front);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        z-index: 1;
      }
    }
    .fc-button.fc-timeGridWeek-button {
      background: var(--color-back-lighter);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: var(--color-front);
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
      &:hover {
        background: #f6f6f6;
        border-color: $border-color_8;
        color: var(--color-front);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        z-index: 1;
      }
    }
    .fc-button.fc-timeGridDay-button {
      background: var(--color-back-lighter);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: var(--color-front);
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
      &:hover {
        background: #f6f6f6;
        border-color: $border-color_8;
        color: var(--color-front);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        z-index: 1;
      }
    }
    .fc-button.fc-dayGridMonth-button.fc-button-active {
      background: #e0e0e1;
      border-color: $border-color_9;
      color: var(--color-front);
      &:hover {
        background: #d9d8d9;
        border-color: $border-color_10;
        color: var(--color-front);
      }
    }
    .fc-button.fc-timeGridWeek-button.fc-button-active {
      background: #e0e0e1;
      border-color: $border-color_9;
      color: var(--color-front);
      &:hover {
        background: #d9d8d9;
        border-color: $border-color_10;
        color: var(--color-front);
      }
    }
    .fc-button.fc-timeGridDay-button.fc-button-active {
      background: #e0e0e1;
      border-color: $border-color_9;
      color: var(--color-front);
      &:hover {
        background: #d9d8d9;
        border-color: $border-color_10;
        color: var(--color-front);
      }
    }
    .fc-button-group {
      .fc-button {


        &:focus {
          background: rgba(63, 81, 181, 0.76);
        }
        &:active {
          background: rgba(63, 81, 181, 0.68);
        }
      }
      .fc-button.fc-dayGridMonth-button {
        &:focus {
          background: #e0e0e1;
          border-color: $border-color_9;
        }
        &:focus.p-highlight {
          background: #d9d8d9;
          border-color: $border-color_10;
        }
      }
      .fc-button.fc-timeGridWeek-button {
        &:focus {
          background: #e0e0e1;
          border-color: $border-color_9;
        }
        &:focus.p-highlight {
          background: #d9d8d9;
          border-color: $border-color_10;
        }
      }
      .fc-button.fc-timeGridDay-button {
        &:focus {
          background: #e0e0e1;
          border-color: $border-color_9;
        }
        &:focus.p-highlight {
          background: #d9d8d9;
          border-color: $border-color_10;
        }
      }
    }
  }
}
.fc.fc-theme-standard {
  .fc-view-harness {
    .fc-scrollgrid {
      border-color: $border-color_13;
    }
    th {
      background: var(--color-back-lighter);
      border-color: $border-color_13;
      color: var(--color-front);
    }
    td {
      color: var(--color-front);
      border-color: $border-color_13;
    }
    .fc-view {
      background: var(--color-back-lighter);
    }
    .fc-popover {
      background: none;
      .fc-popover-header {
        border: 1px solid #e0e0e0;
        padding: 1rem;
        background: var(--color-back-lighter);
        color: var(--color-front);
        .fc-popover-close {
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          font-family: $font-family_2 !important;
          font-size: 1rem;
          width: 2.5rem;
          height: 2.5rem;
          color: var(--color-front-lighter);
          background: transparent;

          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:before {
            content: "\e90b";
          }
          &:enabled {
            &:hover {
              color: var(--color-front-lighter);
              border-color: $border-color_4;
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
      }
      .fc-popover-body {
        padding: 1rem;
        border: 1px solid #e0e0e0;
        background: var(--color-back-lighter);
        color: var(--color-front);
        border-top: 0 none;
      }
    }
    .fc-event.fc-daygrid-block-event {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
      border-color: $border-color_14;
      .fc-event-main {
        color: $color_4;
      }
    }
    .fc-event.fc-daygrid-dot-event {
      .fc-daygrid-event-dot {
        background: rgba(63, 81, 181, 0.12);
        border-color: $border-color_14;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front);
      }
    }
    .fc-cell-shaded {
      background: var(--color-back-lighter);
    }
  }
  .fc-toolbar {
    .fc-button {
      color: $color_5;
      background: $color-primary;
      font-size: 1rem;
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);

      &:enabled {
        &:hover {
          background: rgba(63, 81, 181, 0.92);
          color: $color_5;
          border-color: $border-color_4;
        }
        &:active {
          background: rgba(63, 81, 181, 0.68);
          color: $color_5;
          border-color: $border-color_4;
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
      }
      &:disabled {
        opacity: 0.38;
        color: $color_5;
        background: $color-primary;
      }
      .fc-icon-chevron-left {
        font-family: $font-family_2 !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e900";
        }
      }
      .fc-icon-chevron-right {
        font-family: $font-family_2 !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e901";
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        background: rgba(63, 81, 181, 0.76);
      }
      &:active {
        background: rgba(63, 81, 181, 0.68);
      }
    }
    .fc-button.fc-dayGridMonth-button {
      background: var(--color-back-lighter);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: var(--color-front);
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
      &:hover {
        background: #f6f6f6;
        border-color: $border-color_8;
        color: var(--color-front);
      }
      &:not(:disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
          z-index: 1;
        }
      }
    }
    .fc-button.fc-timeGridWeek-button {
      background: var(--color-back-lighter);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: var(--color-front);
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
      &:hover {
        background: #f6f6f6;
        border-color: $border-color_8;
        color: var(--color-front);
      }
      &:not(:disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
          z-index: 1;
        }
      }
    }
    .fc-button.fc-timeGridDay-button {
      background: var(--color-back-lighter);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: var(--color-front);
      transition: background-color 0.2s, border-color 0.2s, color 0.2s,
        box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
      &:hover {
        background: #f6f6f6;
        border-color: $border-color_8;
        color: var(--color-front);
      }
      &:not(:disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
          z-index: 1;
        }
      }
    }
    .fc-button.fc-dayGridMonth-button.fc-button-active {
      background: #e0e0e1;
      border-color: $border-color_9;
      color: var(--color-front);
      &:hover {
        background: #d9d8d9;
        border-color: $border-color_10;
        color: var(--color-front);
      }
    }
    .fc-button.fc-timeGridWeek-button.fc-button-active {
      background: #e0e0e1;
      border-color: $border-color_9;
      color: var(--color-front);
      &:hover {
        background: #d9d8d9;
        border-color: $border-color_10;
        color: var(--color-front);
      }
    }
    .fc-button.fc-timeGridDay-button.fc-button-active {
      background: #e0e0e1;
      border-color: $border-color_9;
      color: var(--color-front);
      &:hover {
        background: #d9d8d9;
        border-color: $border-color_10;
        color: var(--color-front);
      }
    }
    .fc-button-group {
      .fc-button {


        &:focus {
          background: rgba(63, 81, 181, 0.76);
        }
        &:active {
          background: rgba(63, 81, 181, 0.68);
        }
      }
      .fc-button.fc-dayGridMonth-button {
        &:focus {
          background: #e0e0e1;
          border-color: $border-color_9;
        }
        &:focus.p-highlight {
          background: #d9d8d9;
          border-color: $border-color_10;
        }
      }
      .fc-button.fc-timeGridWeek-button {
        &:focus {
          background: #e0e0e1;
          border-color: $border-color_9;
        }
        &:focus.p-highlight {
          background: #d9d8d9;
          border-color: $border-color_10;
        }
      }
      .fc-button.fc-timeGridDay-button {
        &:focus {
          background: #e0e0e1;
          border-color: $border-color_9;
        }
        &:focus.p-highlight {
          background: #d9d8d9;
          border-color: $border-color_10;
        }
      }
    }
  }
  a {
    color: var(--color-front);
  }
  .fc-highlight {
    color: $color_4;
    background: rgba(63, 81, 181, 0.12);
  }
}
.p-orderlist {
  .p-orderlist-controls {
    padding: 1rem;
    .p-button {
      margin-bottom: 0.5rem;
    }
  }
  .p-orderlist-header {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e0e0e0;
    padding: 1rem;
    border-bottom: 0 none;


    .p-orderlist-title {
      font-weight: 500;
    }
  }
  .p-orderlist-filter-container {
    padding: 1rem;
    background: var(--color-back-lighter);
    border: 1px solid #e0e0e0;
    border-bottom: 0 none;
    .p-orderlist-filter-input {
      padding-right: 2rem;
    }
    .p-orderlist-filter-icon {
      right: 1rem;
      color: var(--color-front-lighter);
    }
  }
  .p-orderlist-list {
    border: 1px solid #e0e0e0;
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 0;


    .p-orderlist-item {
      padding: 1rem 1rem;
      margin: 0;
      color: var(--color-front);
      background: transparent;
      transition: none;
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        background: rgba(0, 0, 0, 0.12);
      }
      &:focus.p-highlight {
        background: rgba(63, 81, 181, 0.24);
      }
    }
    .p-orderlist-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
    .p-orderlist-empty-message {
      padding: 1rem 1rem;
      color: var(--color-front);
    }
    &:not(.cdk-drop-list-dragging) {
      .p-orderlist-item {
        &:not(.p-highlight) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            color: var(--color-front);
          }
        }
      }
    }
  }
}
.p-orderlist.p-orderlist-striped {
  .p-orderlist-list {
    .p-orderlist-item {
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.02);
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
.p-orderlist-item.cdk-drag-preview {
  padding: 1rem 1rem;
  border: 4px solid var(--color-front);
  color: var(--color-front);
  background: var(--color-back-lighter);
  margin: 0;
}
.p-organizationchart {
  .p-organizationchart-node-content.p-organizationchart-selectable-node {
    &:not(.p-highlight) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front);
      }
    }
  }
  .p-organizationchart-node-content.p-highlight {
    background: rgba(63, 81, 181, 0.12);
    color: $color_4;
    .p-node-toggler {
      i {
        color: $color_14;
      }
    }
  }
  .p-organizationchart-line-down {
    background: rgba(0, 0, 0, 0.12);
  }
  .p-organizationchart-line-left {
    border-right: 1px solid #e0e0e0;
    border-color: $border-color_8;
  }
  .p-organizationchart-line-top {
    border-top: 1px solid #e0e0e0;
    border-color: $border-color_8;
  }
  .p-organizationchart-node-content {
    border: 1px solid #e0e0e0;
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 1rem;
    .p-node-toggler {
      background: inherit;
      color: $color_15;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
}
.p-paginator {
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: solid #e4e4e4;
  border-width: 0;
  padding: 0.5rem 1rem;

  justify-content: flex-end;
  .p-paginator-first {
    background-color: $background-color_5;
    color: var(--color-front-lighter);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: none;



    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          border-color: $border-color_4;
          color: var(--color-front-lighter);
        }
      }
    }
  }
  .p-paginator-prev {
    background-color: $background-color_5;
    color: var(--color-front-lighter);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: none;

    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          border-color: $border-color_4;
          color: var(--color-front-lighter);
        }
      }
    }
  }
  .p-paginator-next {
    background-color: $background-color_5;
    color: var(--color-front-lighter);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: none;

    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          border-color: $border-color_4;
          color: var(--color-front-lighter);
        }
      }
    }
  }
  .p-paginator-last {
    background-color: $background-color_5;
    color: var(--color-front-lighter);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    transition: none;



    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          border-color: $border-color_4;
          color: var(--color-front-lighter);
        }
      }
    }
  }
  .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 3rem;
    .p-dropdown-label {
      padding-right: 0;
    }
  }
  .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .p-inputtext {
      max-width: 3rem;
    }
  }
  .p-paginator-current {
    background-color: $background-color_5;
    color: var(--color-front-lighter);
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator-pages {
    .p-paginator-page {
      background-color: $background-color_5;
      color: var(--color-front-lighter);
      min-width: 3rem;
      height: 3rem;
      margin: 0.143rem;
      transition: none;

      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          border-color: $border-color_4;
          color: var(--color-front-lighter);
        }
      }
    }
    .p-paginator-page.p-highlight {
      background: rgba(63, 81, 181, 0.12);
      border-color: $border-color_14;
      color: $color_4;
    }
  }
  .p-paginator-element {
    &:focus {
      background: rgba(0, 0, 0, 0.12);
    }
    &:focus.p-highlight {
      background: rgba(63, 81, 181, 0.24);
    }
  }
}
.p-picklist {
  .p-picklist-buttons {
    padding: 1rem;
    .p-button {
      margin-bottom: 0.5rem;
    }
  }
  .p-picklist-header {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e0e0e0;
    padding: 1rem;
    border-bottom: 0 none;


    .p-picklist-title {
      font-weight: 500;
    }
  }
  .p-picklist-filter-container {
    padding: 1rem;
    background: var(--color-back-lighter);
    border: 1px solid #e0e0e0;
    border-bottom: 0 none;
    .p-picklist-filter-input {
      padding-right: 2rem;
    }
    .p-picklist-filter-icon {
      right: 1rem;
      color: var(--color-front-lighter);
    }
  }
  .p-picklist-list {
    border: 1px solid #e0e0e0;
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 0;


    .p-picklist-item {
      padding: 1rem 1rem;
      margin: 0;
      color: var(--color-front);
      background: transparent;
      transition: none;
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        background: rgba(0, 0, 0, 0.12);
      }
      &:focus.p-highlight {
        background: rgba(63, 81, 181, 0.24);
      }
    }
    .p-picklist-item.p-highlight {
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
    }
    .p-picklist-empty-message {
      padding: 1rem 1rem;
      color: var(--color-front);
    }
    &:not(.cdk-drop-list-dragging) {
      .p-picklist-item {
        &:not(.p-highlight) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            color: var(--color-front);
          }
        }
      }
    }
  }
}
.p-picklist.p-picklist-striped {
  .p-picklist-list {
    .p-picklist-item {
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.02);
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
.p-picklist-item.cdk-drag-preview {
  padding: 1rem 1rem;
  border: 4px solid var(--color-front);
  color: var(--color-front);
  background: var(--color-back-lighter);
  margin: 0;
}
.p-timeline {
  .p-timeline-event-marker {

    width: 1rem;
    height: 1rem;
    background-color: $background-color_8;
  }
  .p-timeline-event-connector {
    background-color: $background-color_8;
  }
}
.p-timeline.p-timeline-vertical {
  .p-timeline-event-opposite {
    padding: 0 1rem;
  }
  .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline-event-connector {
    width: 2px;
    margin: 0.5rem 0;
  }
}
.p-timeline.p-timeline-horizontal {
  .p-timeline-event-opposite {
    padding: 1rem 0;
  }
  .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline-event-connector {
    height: 2px;
    margin: 0 0.5rem;
  }
}
.p-tree {
  border: 1px solid #e0e0e0;
  background: var(--color-back-lighter);
  color: var(--color-front);
  padding: 1rem;

  .p-tree-container {
    .p-treenode {
      padding: 0.25rem;
      .p-treenode-content {

        transition: none;
        padding: 0.5rem;
        .p-tree-toggler {
          margin-right: 0.5rem;
          width: 2.5rem;
          height: 2.5rem;
          color: var(--color-front-lighter);
          background: transparent;

          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: var(--color-front-lighter);
              border-color: $border-color_4;
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
        .p-treenode-icon {
          margin-right: 0.5rem;
          color: var(--color-front-lighter);
        }
        .p-checkbox {
          margin-right: 0.5rem;
          .p-indeterminate {
            .p-checkbox-icon {
              color: var(--color-front);
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
          background: rgba(0, 0, 0, 0.12);
        }
        &:focus.p-highlight {
          background: rgba(63, 81, 181, 0.24);
        }
      }
      .p-treenode-content.p-highlight {
        background: rgba(63, 81, 181, 0.12);
        color: $color_4;
        .p-tree-toggler {
          color: $color_4;
          &:hover {
            color: $color_4;
          }
        }
        .p-treenode-icon {
          color: $color_4;
          &:hover {
            color: $color_4;
          }
        }
      }
      .p-treenode-content.p-treenode-selectable {
        &:not(.p-highlight) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            color: var(--color-front);
          }
        }
      }
      .p-treenode-content.p-treenode-dragover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front);
      }
    }
  }
  .p-tree-filter-container {
    margin-bottom: 0.5rem;
    .p-tree-filter {
      width: 100%;
      padding-right: 2rem;
    }
    .p-tree-filter-icon {
      right: 1rem;
      color: var(--color-front-lighter);
    }
  }
  .p-treenode-children {
    padding: 0 0 0 1rem;
  }
  .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: $background-color_9;
  }
}
.p-tree.p-tree-horizontal {
  .p-treenode {
    .p-treenode-content {

      border: 1px solid #e0e0e0;
      background-color: var(--color-back-lighter);
      color: var(--color-front);
      padding: 0.5rem;
      transition: none;
      .p-tree-toggler {
        margin-right: 0.5rem;
      }
      .p-treenode-icon {
        color: var(--color-front-lighter);
        margin-right: 0.5rem;
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
      .p-treenode-label {
        &:not(.p-highlight) {
          &:hover {
            background-color: $background-color_11;
            color: $color_15;
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    .p-treenode-content.p-highlight {
      background-color: $background-color_10;
      color: $color_4;
      .p-treenode-icon {
        color: $color_4;
      }
    }
    .p-treenode-content.p-treenode-selectable {
      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          color: var(--color-front);
        }
      }
    }
  }
}
.p-treetable {
  .p-paginator-top {
    border-width: 0 0 1px 0;

  }
  .p-paginator-bottom {
    border-width: 0 0 1px 0;

  }
  .p-treetable-header {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
  }
  .p-treetable-footer {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
  }
  .p-treetable-thead {
    > tr {
      > th {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e4e4e4;
        border-width: 0 0 1px 0;
        font-weight: 500;
        color: var(--color-front);
        background: var(--color-back-lighter);
        transition: none;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid #e4e4e4;
        border-width: 0 0 1px 0;
        font-weight: 500;
        color: var(--color-front);
        background: var(--color-back-lighter);
      }
    }
  }
  .p-sortable-column {
    outline-color: $outline-color_1;
    outline: 0 none;
    .p-sortable-column-icon {
      color: var(--color-front-lighter);
      margin-left: 0.5rem;
    }
    .p-sortable-column-badge {

      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: $color_4;
      background: rgba(63, 81, 181, 0.12);
      margin-left: 0.5rem;
    }
    &:not(.p-highlight) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front);
        .p-sortable-column-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      background-color: $background-color_29;
    }
  }
  .p-sortable-column.p-highlight {
    background: var(--color-back-lighter);
    color: var(--color-front);
    .p-sortable-column-icon {
      color: var(--color-front);
    }
  }
  .p-treetable-tbody {
    > tr {
      background: var(--color-back-lighter);
      color: var(--color-front);
      transition: none;
      outline: 0 none;
      > td {
        text-align: left;
        border: 1px solid #e4e4e4;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        .p-treetable-toggler {
          width: 2.5rem;
          height: 2.5rem;
          color: var(--color-front-lighter);
          background: transparent;

          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin-right: 0.5rem;
          &:enabled {
            &:hover {
              color: var(--color-front-lighter);
              border-color: $border-color_4;
              background: rgba(0, 0, 0, 0.04);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
        }
        p-treetablecheckbox {
          .p-checkbox {
            margin-right: 0.5rem;
            .p-indeterminate {
              .p-checkbox-icon {
                color: var(--color-front);
              }
            }
          }
        }
      }
      &:focus {
        outline: 0.15rem solid transparent;
        outline-offset: -0.15rem;
      }
      &:not(.p-highlight) {
        &:focus {
          background-color: $background-color_29;
        }
      }
    }
    > tr.p-highlight {
      background: rgba(63, 81, 181, 0.12);
      color: $color_4;
      .p-treetable-toggler {
        color: $color_4;
        &:hover {
          color: $color_4;
        }
      }
    }
  }
  .p-column-resizer-helper {
    background: $color-primary;
  }
  .p-treetable-scrollable-header {
    background: var(--color-back-lighter);
  }
  .p-treetable-scrollable-footer {
    background: var(--color-back-lighter);
  }
  .p-treetable-loading-icon {
    font-size: 2rem;
  }
}
.p-treetable.p-treetable-hoverable-rows {
  .p-treetable-tbody {
    > tr {
      &:not(.p-highlight) {
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          color: var(--color-front);
          .p-treetable-toggler {
            color: var(--color-front);
          }
        }
      }
    }
  }
}
.p-treetable.p-treetable-gridlines {
  .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable-thead {
    > tr {
      > th {
        border-width: 1px;
      }
    }
  }
  .p-treetable-tbody {
    > tr {
      > td {
        border-width: 1px;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        border-width: 1px;
      }
    }
  }
}
.p-treetable.p-treetable-sm {
  .p-treetable-header {
    padding: 0.875rem 0.875rem;
  }
  .p-treetable-thead {
    > tr {
      > th {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tbody {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 0.5rem 0.5rem;
  }
}
.p-treetable.p-treetable-lg {
  .p-treetable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable-thead {
    > tr {
      > th {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tbody {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tfoot {
    > tr {
      > td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 1.25rem 1.25rem;
  }
}
.p-virtualscroller {
  .p-virtualscroller-header {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;
  }
  .p-virtualscroller-content {
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 1rem 0;
  }
  .p-virtualscroller-footer {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 1px solid #e4e4e4;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 500;


  }
}
.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: 1.5rem;
      color: var(--color-front);
      background: var(--color-back-lighter);
      font-weight: 400;

      transition: none;
      .p-accordion-toggle-icon {
        margin-right: 0.5rem;
      }
    }
    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            background: #f6f6f6;
            border-color: $border-color_4;
            color: var(--color-front);
          }
        }
      }
    }
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: var(--color-back-lighter);
        border-color: $border-color_4;
        color: var(--color-front);


      }
      &:hover {
        .p-accordion-header-link {
          border-color: $border-color_4;
          background: var(--color-back-lighter);
          color: var(--color-front);
        }
      }
    }
  }
  .p-accordion-content {
    padding: 1rem 1.5rem;
    background: var(--color-back-lighter);
    color: var(--color-front);
    border-top: 0;




  }
  p-accordiontab {
    .p-accordion-tab {
      margin-bottom: 0;
    }

    &:not(:first-child) {
      .p-accordion-header {
        .p-accordion-header-link {
          border-top: 0 none;
        }
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              .p-accordion-header-link {
                border-top: 0 none;
              }
            }
          }
        }
        &:not(.p-disabled).p-highlight {
          &:hover {
            .p-accordion-header-link {
              border-top: 0 none;
            }
          }
        }
      }
    }

  }
  .p-accordion-tab {
    border: 4px solid var(--color-front);
    margin-bottom: 0;

    position: relative;
    transition: margin-bottom 225ms;

    .p-accordion-toggle-icon {
      order: 1;
      margin-left: auto;
      transition: transform 0.2s;
    }
    &:not(.p-accordion-tab-active) {
      .p-accordion-header-link {
        &:focus {
          background: #f6f6f6;
        }
      }
      .p-accordion-toggle-icon {
        transform: rotate(-270deg);
      }
    }
    .p-accordion-header.p-disabled {
      opacity: 1;
      .p-accordion-header-link {
        > * {
          opacity: 0.38;
        }
      }
    }
  }
  .p-accordion-tab.p-accordion-tab-active {
    margin-bottom: 1rem;
    .p-accordion-toggle-icon {
      transform: rotate(-180deg);
    }
  }
}
.p-card {
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 4px solid var(--color-front);

  .p-card-body {
    padding: 1rem;
  }
  .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: var(--color-front-lighter);
  }
  .p-card-content {
    padding: 1rem 0;
  }
  .p-card-footer {
    padding: 1rem 0 0 0;
  }
}
.p-divider {
  .p-divider-content {
    background-color: var(--color-back-lighter);
  }
}
.p-divider.p-divider-horizontal {
  margin: 1.25rem 0;
  padding: 0 1.25rem;
  &:before {
    border-top: 1px rgba(0, 0, 0, 0.12);
  }
  .p-divider-content {
    padding: 0 0.5rem;
  }
}
.p-divider.p-divider-vertical {
  margin: 0 1.25rem;
  padding: 1.25rem 0;
  &:before {
    border-left: 1px rgba(0, 0, 0, 0.12);
  }
  .p-divider-content {
    padding: 0.5rem 0;
  }
}
.p-fieldset {
  border: 1px solid #e0e0e0;
  background: var(--color-back-lighter);
  color: var(--color-front);

  .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    color: var(--color-front);
    background: var(--color-back-lighter);
    font-weight: 500;

  }
  .p-fieldset-content {
    padding: 1rem;
  }
}
.p-fieldset.p-fieldset-toggleable {
  .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    a {
      padding: 1rem;
      color: var(--color-front);

      transition: none;
      .p-fieldset-toggler {
        margin-right: 0.5rem;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        background: rgba(0, 0, 0, 0.12);
      }
    }
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      border-color: $border-color_13;
      color: var(--color-front);
    }
  }
}
.p-panel {
  .p-panel-header {
    border: 1px solid #e0e0e0;
    padding: 1rem;
    background: var(--color-back-lighter);
    color: var(--color-front);


    .p-panel-title {
      font-weight: 500;
    }
    .p-panel-header-icon {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
  .p-panel-content {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    background: var(--color-back-lighter);
    color: var(--color-front);


    border-top: 0 none;
    padding-top: 0;
  }
  .p-panel-footer {
    padding: 1rem 1rem;
    border: 1px solid #e0e0e0;
    background: var(--color-back-lighter);
    color: var(--color-front);
    border-top: 0 none;
  }
  .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }
  .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }
  .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }

  border: 4px solid var(--color-front);
  .p-panel-title {
    font-size: 1.25rem;
  }
  .p-panel-header-icon {
    &:focus {
      background: rgba(0, 0, 0, 0.12);
    }
  }
}
.p-panel.p-panel-toggleable {
  .p-panel-header {
    padding: 0.5rem 1rem;
  }
}
.p-scrollpanel {
  .p-scrollpanel-bar {
    background: rgba(0, 0, 0, 0.12);
  }
}
.p-splitter {
  border: 1px solid #e0e0e0;
  background: var(--color-back-lighter);

  color: var(--color-front);
  .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: rgba(0, 0, 0, 0.04);
    .p-splitter-gutter-handle {
      background: rgba(0, 0, 0, 0.12);
    }
  }
  .p-splitter-gutter-resizing {
    background: rgba(0, 0, 0, 0.12);
  }
}
.p-tabview {
  .p-tabview-nav {
    background: var(--color-back-lighter);
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0 1px 0;
    position: relative;
    li {
      margin-right: 0;
      .p-tabview-nav-link {
        border: solid rgba(0, 0, 0, 0.12);
        border-width: 0 0 1px 0;
        border-color: $border-color_15;
        background: var(--color-back-lighter);
        color: var(--color-front-lighter);
        padding: 1rem 1.5rem;
        font-weight: 500;


        transition: none;
        margin: 0 0 0 0;
        transition: background-color 0.2s;

        &:not(.p-disabled) {
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset none;
          }
        }
        > .p-ink {
          background-color: $background-color_22;
        }
        &:focus {
          background-color: $background-color_10;
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-tabview-nav-link {
              background: rgba(63, 81, 181, 0.04);
              border-color: $border-color_8;
              color: var(--color-front-lighter);
            }
          }
        }
      }
    }
    li.p-highlight {
      .p-tabview-nav-link {
        background: var(--color-back-lighter);
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    .p-tabview-ink-bar {
      z-index: 1;
      display: block;
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: $background-color_6;
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
    }
  }
  .p-tabview-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabview-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview-nav-btn.p-link {
    background: var(--color-back-lighter);
    color: $color_4;
    width: 3rem;
    border: 4px solid var(--color-front);

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset none;
    }
  }
  .p-tabview-panels {
    background: var(--color-back-lighter);
    padding: 1rem;
    color: var(--color-front);


  }
}
.p-toolbar {
  background: var(--color-back-lighter);
  border: 1px solid #e0e0e0;
  padding: 1rem;

  .p-toolbar-separator {
    margin: 0 0.5rem;
  }
}
.p-confirm-popup {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  -webkit-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  .p-confirm-popup-content {
    padding: 1rem;
  }
  .p-confirm-popup-footer {
    text-align: right;
    padding: 1rem 1rem;
    button {
      margin: 0 0.5rem 0 0;
      width: auto;
      &:last-child {
        margin: 0;
      }
    }
  }
  &:after {
    border: 4px solid;
    border-color: $border-color_16;
    border-bottom-color: $border-bottom-color_2;
  }
  &:before {
    border: 4px solid;
    border-color: $border-color_16;
    border-bottom-color: #ffffff00;
  }
  .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup-message {
    margin-left: 1rem;
  }
}
.p-confirm-popup.p-confirm-popup-flipped {
  &:after {
    border-top-color: $border-top-color_1;
  }
  &:before {
    border-top-color: $border-top-color_1;
  }
}
.p-dialog {

  border: 4px solid var(--color-front);

  -webkit-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  .p-dialog-header {
    border-bottom: 0 none;
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 1.5rem;


    .p-dialog-title {
      font-weight: 500;
      font-size: 1.25rem;
    }
    .p-dialog-header-icon {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .p-dialog-content {
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 0 1.5rem 1.5rem 1.5rem;

  }
  .p-dialog-footer {
    border-top: 0 none;
    background: var(--color-back-lighter);
    color: var(--color-front);
    padding: 1rem 1.5rem;
    text-align: right;


    button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }
  }
}
.p-dialog.p-confirm-dialog {
  .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-confirm-dialog-message {
    margin-left: 1rem;
  }
}
.p-overlaypanel {
  background: var(--color-back-lighter);
  color: var(--color-front);

  border: 4px solid var(--color-front);
  -webkit-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
  .p-overlaypanel-content {
    padding: 1rem;
    padding: 1.5rem;
  }
  .p-overlaypanel-close {
    background: $color-primary;
    color: $color_5;
    width: 2.5rem;
    height: 2.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    position: absolute;
    top: -1.25rem;
    right: -1.25rem;
    &:enabled {
      &:hover {
        background: rgba(63, 81, 181, 0.92);
        color: $color_5;
      }
    }
  }
  &:after {
    border: solid transparent;
    border-color: $border-color_16;
    border-bottom-color: $border-bottom-color_1;
  }
  &:before {
    border: solid;
    border-color: $border-color_16;
    border-bottom-color: $border-bottom-color_2;
  }
}
.p-overlaypanel.p-overlaypanel-flipped {
  &:after {
    border-top-color: $border-top-color_1;
  }
  &:before {
    border-top-color: $border-top-color_1;
  }
}
.p-sidebar {
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 4px solid var(--color-front);
  .p-sidebar-header {
    padding: 1rem;
    .p-sidebar-close {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    .p-sidebar-icon {
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);
      background: transparent;

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: var(--color-front-lighter);
          border-color: $border-color_4;
          background: rgba(0, 0, 0, 0.04);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    + {
      .p-sidebar-content {
        padding-top: 0;
      }
    }
  }
  .p-sidebar-content {
    padding: 1rem;
  }
}
.p-tooltip {
  .p-tooltip-text {
    background: rgba(97, 97, 97, 0.9);
    color: $color_5;
    padding: 0.5rem;
    border: 4px solid var(--color-front);
    -webkit-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
    -moz-box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);
    box-shadow: 13px 12px 1px 0px rgb(0 0 0 / 75%);

    box-shadow: none;
    font-size: 0.875rem;
  }
  .p-tooltip-arrow {
    display: none;
  }
}
.p-tooltip.p-tooltip-right {
  .p-tooltip-arrow {
    border-right-color: $border-right-color_1;
  }
}
.p-tooltip.p-tooltip-left {
  .p-tooltip-arrow {
    border-left-color: $border-left-color_1;
  }
}
.p-tooltip.p-tooltip-top {
  .p-tooltip-arrow {
    border-top-color: $border-top-color_2;
  }
}
.p-tooltip.p-tooltip-bottom {
  .p-tooltip-arrow {
    border-bottom-color: $border-bottom-color_3;
  }
}
.p-fileupload {
  .p-fileupload-buttonbar {
    background: var(--color-back-lighter);
    padding: 1rem;
    border: 1px solid #e0e0e0;
    color: var(--color-front);
    border-bottom: 0 none;


    .p-button {
      margin-right: 0.5rem;
    }
    .p-button.p-fileupload-choose.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  .p-fileupload-content {
    background: var(--color-back-lighter);
    padding: 2rem 1rem;
    border: 1px solid #e0e0e0;
    color: var(--color-front);


  }
  .p-progressbar {
    height: 4px;
  }
  .p-fileupload-row {
    > div {
      padding: 1rem 1rem;
    }
  }
}
.p-fileupload.p-fileupload-advanced {
  .p-message {
    margin-top: 0;
  }
}
.p-fileupload-choose {
  &:not(.p-disabled) {
    &:hover {
      background: rgba(63, 81, 181, 0.92);
      color: $color_5;
      border-color: $border-color_4;
    }
    &:active {
      background: rgba(63, 81, 181, 0.68);
      color: $color_5;
      border-color: $border-color_4;
    }
  }
}
.p-breadcrumb {
  background: var(--color-back-lighter);
  border: 1px solid #e5e5e5;

  padding: 1rem;
  ul {
    li {
      .p-menuitem-link {
        transition: none;

        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
      }
      &:last-child {
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    li.p-breadcrumb-chevron {
      margin: 0 0.5rem 0 0.5rem;
      color: var(--color-front-lighter);
    }
  }
  .p-menuitem-link {
    padding: 0.25rem 0.5rem;
    &:focus {
      background: rgba(0, 0, 0, 0.12);
    }
  }
}
.p-contextmenu {
  padding: 0.5rem 0;
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 4px solid var(--color-front);

  width: 12.5rem;
  .p-menuitem-link {
    padding: 1rem 1rem;
    color: var(--color-front);

    transition: none;
    user-select: none;
    .p-menuitem-text {
      color: var(--color-front);
    }
    .p-menuitem-icon {
      color: var(--color-front-lighter);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--color-front-lighter);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  .p-submenu-list {
    padding: 0.5rem 0;
    background: var(--color-back-lighter);
    border: 4px solid var(--color-front);

  }
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
  .p-menuitem.p-menuitem-active {
    > .p-menuitem-link {
      background: rgba(0, 0, 0, 0.04);
      .p-menuitem-text {
        color: var(--color-front);
      }
      .p-menuitem-icon {
        color: var(--color-front-lighter);
      }
      .p-submenu-icon {
        color: var(--color-front-lighter);
      }
    }
  }
  .p-menu-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0.5rem 0;
  }
  .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menuitem-badge {
    background: $color-primary;
    color: $color_5;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.p-dock {
  .p-dock-list {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;

  }
  .p-dock-item {
    padding: 0.5rem;
  }
  .p-dock-action {
    width: 4rem;
    height: 4rem;
  }
}
.p-dock.p-dock-top {
  .p-dock-item-second-prev {
    margin: 0 0.9rem;
  }
  .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock-item-prev {
    margin: 0 1.3rem;
  }
  .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock-item-current {
    margin: 0 1.5rem;
  }
}
.p-dock.p-dock-bottom {
  .p-dock-item-second-prev {
    margin: 0 0.9rem;
  }
  .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock-item-prev {
    margin: 0 1.3rem;
  }
  .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock-item-current {
    margin: 0 1.5rem;
  }
}
.p-dock.p-dock-left {
  .p-dock-item-second-prev {
    margin: 0.9rem 0;
  }
  .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock-item-prev {
    margin: 1.3rem 0;
  }
  .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock-item-current {
    margin: 1.5rem 0;
  }
}
.p-dock.p-dock-right {
  .p-dock-item-second-prev {
    margin: 0.9rem 0;
  }
  .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock-item-prev {
    margin: 1.3rem 0;
  }
  .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock-item-current {
    margin: 1.5rem 0;
  }
}
.p-megamenu {
  padding: 1rem;
  background: transparent;
  color: var(--color-front);
  border: 1px solid #e5e5e5;

  .p-megamenu-root-list {
    > .p-menuitem {
      > .p-menuitem-link {
        padding: 1rem;
        color: var(--color-front);

        transition: none;
        user-select: none;
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
          margin-left: 0.5rem;
        }
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            .p-menuitem-text {
              color: var(--color-front);
            }
            .p-menuitem-icon {
              color: var(--color-front-lighter);
            }
            .p-submenu-icon {
              color: var(--color-front-lighter);
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }
    }
    > .p-menuitem.p-menuitem-active {
      > .p-menuitem-link {
        background: rgba(0, 0, 0, 0.04);
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            .p-menuitem-text {
              color: var(--color-front);
            }
            .p-menuitem-icon {
              color: var(--color-front-lighter);
            }
            .p-submenu-icon {
              color: var(--color-front-lighter);
            }
          }
        }
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
  }
  .p-menuitem-link {
    padding: 1rem 1rem;
    color: var(--color-front);

    transition: none;
    user-select: none;
    .p-menuitem-text {
      color: var(--color-front);
    }
    .p-menuitem-icon {
      color: var(--color-front-lighter);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--color-front-lighter);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  .p-megamenu-panel {
    background: var(--color-back-lighter);
    color: var(--color-front);
    border: 4px solid var(--color-front);
  }
  .p-megamenu-submenu-header {
    margin: 0;
    padding: 1rem;
    color: var(--color-front-lighter);
    background: var(--color-back-lighter);
    font-weight: 400;


  }
  .p-megamenu-submenu {
    padding: 0.5rem 0;
    width: 12.5rem;
    .p-menu-separator {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      margin: 0.5rem 0;
    }
    .p-menuitem {
      margin: 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-menuitem.p-menuitem-active {
    > .p-menuitem-link {
      background: rgba(0, 0, 0, 0.04);
      .p-menuitem-text {
        color: var(--color-front);
      }
      .p-menuitem-icon {
        color: var(--color-front-lighter);
      }
      .p-submenu-icon {
        color: var(--color-front-lighter);
      }
    }
  }
  .p-menuitem-badge {
    background: $color-primary;
    color: $color_5;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-menuitem {
    .p-menuitem-link {
      &:focus {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
}
.p-menu {
  padding: 0.5rem 0;
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 1px solid #e5e5e5;

  width: 12.5rem;
  .p-menuitem-link {
    padding: 1rem 1rem;
    color: var(--color-front);

    transition: none;
    user-select: none;
    .p-menuitem-text {
      color: var(--color-front);
    }
    .p-menuitem-icon {
      color: var(--color-front-lighter);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--color-front-lighter);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  .p-submenu-header {
    margin: 0;
    padding: 1rem;
    color: var(--color-front-lighter);
    background: var(--color-back-lighter);
    font-weight: 400;


  }
  .p-menu-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0.5rem 0;
  }
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
    .p-menuitem-link {
      &:focus {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
  .p-menuitem-badge {
    background: $color-primary;
    color: $color_5;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.p-menu.p-menu-overlay {
  background: var(--color-back-lighter);
  border: 4px solid var(--color-front);
}
.p-menubar {
  padding: 1rem;
  background: transparent;
  color: var(--color-front);
  border: 1px solid #e5e5e5;

  .p-menuitem-link {
    padding: 1rem 1rem;
    color: var(--color-front);

    transition: none;
    user-select: none;
    .p-menuitem-text {
      color: var(--color-front);
    }
    .p-menuitem-icon {
      color: var(--color-front-lighter);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--color-front-lighter);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  .p-menubar-root-list {
    > .p-menuitem {
      > .p-menuitem-link {
        padding: 1rem;
        color: var(--color-front);

        transition: none;
        user-select: none;
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
          margin-left: 0.5rem;
        }
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            .p-menuitem-text {
              color: var(--color-front);
            }
            .p-menuitem-icon {
              color: var(--color-front-lighter);
            }
            .p-submenu-icon {
              color: var(--color-front-lighter);
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }
    }
    > .p-menuitem.p-menuitem-active {
      > .p-menuitem-link {
        background: rgba(0, 0, 0, 0.04);
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            .p-menuitem-text {
              color: var(--color-front);
            }
            .p-menuitem-icon {
              color: var(--color-front-lighter);
            }
            .p-submenu-icon {
              color: var(--color-front-lighter);
            }
          }
        }
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
  }
  .p-submenu-list {
    padding: 0.5rem 0;
    background: var(--color-back-lighter);
    border: 4px solid var(--color-front);
    width: 12.5rem;
    .p-menu-separator {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      margin: 0.5rem 0;
    }
    .p-submenu-icon {
      font-size: 0.875rem;
    }
    .p-menuitem {
      margin: 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-menuitem.p-menuitem-active {
    > .p-menuitem-link {
      background: rgba(0, 0, 0, 0.04);
      .p-menuitem-text {
        color: var(--color-front);
      }
      .p-menuitem-icon {
        color: var(--color-front-lighter);
      }
      .p-submenu-icon {
        color: var(--color-front-lighter);
      }
    }
  }
  .p-menuitem-badge {
    background: $color-primary;
    color: $color_5;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-menuitem {
    .p-menuitem-link {
      &:focus {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
}
.p-panelmenu {
  .p-panelmenu-header {
    > a {
      padding: 1.5rem;
      color: var(--color-front);
      background: var(--color-back-lighter);
      font-weight: 400;

      transition: none;
      .p-panelmenu-icon {
        margin-right: 0.5rem;
      }
      .p-menuitem-icon {
        margin-right: 0.5rem;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        > a {
          &:hover {
            background: #f6f6f6;
            border-color: $border-color_4;
            color: var(--color-front);
          }
        }
      }
    }
  }
  .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
    > a {
      background: var(--color-back-lighter);
      border-color: $border-color_4;
      color: var(--color-front);


    }
    &:not(.p-disabled) {
      > a {
        &:hover {
          border-color: $border-color_4;
          background: var(--color-back-lighter);
          color: var(--color-front);
        }
      }
    }
  }
  .p-panelmenu-content {
    padding: 0.5rem 0;
    background: var(--color-back-lighter);
    color: var(--color-front);
    margin-bottom: 0;
    border-top: 0;




    .p-menuitem {
      .p-menuitem-link {
        padding: 1rem 1rem;
        color: var(--color-front);

        transition: none;
        user-select: none;
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            .p-menuitem-text {
              color: var(--color-front);
            }
            .p-menuitem-icon {
              color: var(--color-front-lighter);
            }
            .p-submenu-icon {
              color: var(--color-front-lighter);
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
        .p-panelmenu-icon {
          margin-right: 0.5rem;
        }
      }
    }
    .p-submenu-list {
      &:not(.p-panelmenu-root-submenu) {
        padding: 0 0 0 1rem;
      }
    }
  }
  .p-panelmenu-panel {
    margin-bottom: 0;
    border: 4px solid var(--color-front);
    margin-bottom: 0;

    position: relative;
    transition: margin-bottom 225ms;
    .p-panelmenu-header {

      .p-panelmenu-icon {
        order: 1;
        margin-left: auto;
        margin-right: 0;
      }
      .p-panelmenu-header-link {
        &:focus {
          background: #f6f6f6;
        }
      }
    }

    &:not(:first-child) {
      .p-panelmenu-header {
        > a {
          border-top: 0 none;
        }
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              > a {
                border-top: 0 none;
              }
            }
          }
        }
        &:not(.p-disabled).p-highlight {
          &:hover {
            > a {
              border-top: 0 none;
            }
          }
        }
      }
    }

    .p-panelmenu-header.p-disabled {
      opacity: 1;
      .p-panelmenu-header-link {
        > * {
          opacity: 0.38;
        }
      }
    }
    .p-menuitem {
      .p-menuitem-link {
        &:focus {
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
  .p-menuitem-badge {
    background: $color-primary;
    color: $color_5;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.p-slidemenu {
  padding: 0.5rem 0;
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 1px solid #e5e5e5;

  width: 12.5rem;
  .p-menuitem-link {
    padding: 1rem 1rem;
    color: var(--color-front);

    transition: none;
    user-select: none;
    .p-menuitem-text {
      color: var(--color-front);
    }
    .p-menuitem-icon {
      color: var(--color-front-lighter);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--color-front-lighter);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      background: rgba(0, 0, 0, 0.12);
    }
  }
  .p-slidemenu-list {
    padding: 0.5rem 0;
    background: var(--color-back-lighter);
    border: 4px solid var(--color-front);
  }
  .p-slidemenu.p-slidemenu-active {
    > .p-slidemenu-link {
      background: rgba(0, 0, 0, 0.04);
      .p-slidemenu-text {
        color: var(--color-front);
      }
      .p-slidemenu-icon {
        color: var(--color-front-lighter);
        color: var(--color-front-lighter);
      }
    }
  }
  .p-slidemenu-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0.5rem 0;
  }
  .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-slidemenu-backward {
    padding: 1rem 1rem;
    color: var(--color-front);
  }
  .p-menuitem-badge {
    background: $color-primary;
    color: $color_5;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.p-slidemenu.p-slidemenu-overlay {
  background: var(--color-back-lighter);
  border: 4px solid var(--color-front);
}
.p-steps {
  .p-steps-item {
    .p-menuitem-link {
      background: transparent;
      transition: none;

      background: transparent;
      flex-direction: row;
      flex: 1 1 auto;
      overflow: visible;
      padding: 1rem 0.5rem;
      .p-steps-number {
        color: var(--color-front);
        border: 1px solid transparent;
        background: transparent;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;

        background-color: $background-color_31;
        color: $color_5;
        font-size: 0.857rem;
        min-width: 1.714rem;
        height: 1.714rem;
        line-height: 1.714rem;
      }
      .p-steps-title {
        margin-top: 0.5rem;
        color: var(--color-front);
        margin: 0;
        padding-left: 0.5rem;
      }
      &:not(.p-disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
    &:before {
      content: " ";
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      width: 100%;
      top: 50%;
      left: 0;
      display: block;
      position: absolute;
      margin-top: -1rem;
      position: static;
      left: auto;
      top: auto;
      margin-top: 0;
    }
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: center;
    &:last-child {
      flex-grow: 0;
      &:before {
        display: none;
      }
    }
  }
  .p-steps-item.p-highlight {
    .p-steps-number {
      background: rgba(63, 81, 181, 0.12);
      color: $color_4;
      background-color: $background-color_6;
      color: $color_5;
    }
    .p-steps-title {
      font-weight: 500;
      color: var(--color-front);
      font-weight: 600;
      color: var(--color-front);
    }
  }
  padding: 1rem 0;
  .p-steps-item.p-disabled {
    opacity: 1;
  }
}
.p-tabmenu {
  .p-tabmenu-nav {
    background: var(--color-back-lighter);
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0 1px 0;
    position: relative;
    .p-tabmenuitem {
      margin-right: 0;
      .p-menuitem-link {
        border: solid rgba(0, 0, 0, 0.12);
        border-width: 0 0 1px 0;
        border-color: $border-color_15;
        background: var(--color-back-lighter);
        color: var(--color-front-lighter);
        padding: 1rem 1.5rem;
        font-weight: 500;


        transition: none;
        margin: 0 0 0 0;
        .p-menuitem-icon {
          margin-right: 0.5rem;
        }
        &:not(.p-disabled) {
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset none;
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-menuitem-link {
              background: rgba(63, 81, 181, 0.04);
              border-color: $border-color_8;
              color: var(--color-front-lighter);
            }
          }
        }
      }
    }
    .p-tabmenuitem.p-highlight {
      .p-menuitem-link {
        background: var(--color-back-lighter);
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    li {
      .p-menuitem-link {
        transition: background-color 0.2s;

        > .p-ink {
          background-color: $background-color_22;
        }
        &:focus {
          background-color: $background-color_10;
        }
      }
    }
    li.p-highlight {
      .p-menuitem-link {
        &:focus {
          background-color: $background-color_10;
        }
      }
    }
    .p-tabmenu-ink-bar {
      display: block;
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: $background-color_6;
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
    }
  }
  .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabmenu-nav-btn.p-link {
    background: var(--color-back-lighter);
    color: $color_4;
    width: 3rem;
    border: 4px solid var(--color-front);

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset none;
    }
  }
}
.p-tieredmenu {
  padding: 0.5rem 0;
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 1px solid #e5e5e5;

  width: 12.5rem;
  .p-menuitem-link {
    padding: 1rem 1rem;
    color: var(--color-front);

    transition: none;
    user-select: none;
    .p-menuitem-text {
      color: var(--color-front);
    }
    .p-menuitem-icon {
      color: var(--color-front-lighter);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--color-front-lighter);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        .p-menuitem-text {
          color: var(--color-front);
        }
        .p-menuitem-icon {
          color: var(--color-front-lighter);
        }
        .p-submenu-icon {
          color: var(--color-front-lighter);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      background: rgba(0, 0, 0, 0.12);
    }
  }
  .p-submenu-list {
    padding: 0.5rem 0;
    background: var(--color-back-lighter);
    border: 4px solid var(--color-front);
  }
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
  .p-menuitem.p-menuitem-active {
    > .p-menuitem-link {
      background: rgba(0, 0, 0, 0.04);
      .p-menuitem-text {
        color: var(--color-front);
      }
      .p-menuitem-icon {
        color: var(--color-front-lighter);
      }
      .p-submenu-icon {
        color: var(--color-front-lighter);
      }
    }
  }
  .p-menu-separator {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0.5rem 0;
  }
  .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menuitem-badge {
    background: $color-primary;
    color: $color_5;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: var(--color-back-lighter);
  border: 4px solid var(--color-front);
}
.p-inline-message {
  padding: 1rem 1rem;
  margin: 0;

  .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-inline-message-text {
    font-size: 1rem;
  }
}
.p-inline-message.p-inline-message-info {
  background: #b3e5fc;
  border: solid transparent;
  border-width: 1px;
  color: $color_16;
  .p-inline-message-icon {
    color: $color_16;
  }
}
.p-inline-message.p-inline-message-success {
  background: #c8e6c9;
  border: solid transparent;
  border-width: 1px;
  color: $color_17;
  .p-inline-message-icon {
    color: $color_17;
  }
}
.p-inline-message.p-inline-message-warn {
  background: #ffecb3;
  border: solid transparent;
  border-width: 1px;
  color: $color_18;
  .p-inline-message-icon {
    color: $color_18;
  }
}
.p-inline-message.p-inline-message-error {
  background: #ffcdd2;
  border: solid transparent;
  border-width: 1px;
  color: $color_19;
  .p-inline-message-icon {
    color: $color_19;
  }
}
.p-inline-message.p-inline-message-icon-only {
  .p-inline-message-icon {
    margin-right: 0;
  }
}
.p-message {
  margin: 1rem 0;

  .p-message-wrapper {
    padding: 1.25rem 1.5rem;
  }
  .p-message-close {
    width: 2.5rem;
    height: 2.5rem;

    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .p-message-summary {
    font-weight: 700;
  }
  .p-message-detail {
    margin-left: 0.5rem;
  }
}
.p-message.p-message-info {
  background: #b3e5fc;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color_16;
  .p-message-icon {
    color: $color_16;
  }
  .p-message-close {
    color: $color_16;
  }
}
.p-message.p-message-success {
  background: #c8e6c9;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color_17;
  .p-message-icon {
    color: $color_17;
  }
  .p-message-close {
    color: $color_17;
  }
}
.p-message.p-message-warn {
  background: #ffecb3;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color_18;
  .p-message-icon {
    color: $color_18;
  }
  .p-message-close {
    color: $color_18;
  }
}
.p-message.p-message-error {
  background: #ffcdd2;
  border: solid transparent;
  border-width: 0 0 0 0;
  color: $color_19;
  .p-message-icon {
    color: $color_19;
  }
  .p-message-close {
    color: $color_19;
  }
}
.p-toast {
  .p-toast-message {
    margin: 0 0 1rem 0;
    border: 4px solid var(--color-front);

    .p-toast-message-content {
      padding: 1.5rem;
      border-width: 0 0 0 0;
      .p-toast-message-text {
        margin: 0 0 0 1rem;
      }
      .p-toast-message-icon {
        font-size: 2rem;
      }
      .p-toast-summary {
        font-weight: 700;
      }
      .p-toast-detail {
        margin: 0.5rem 0 0 0;
      }
    }
    .p-toast-icon-close {
      width: 2rem;
      height: 2rem;

      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
  .p-toast-message.p-toast-message-info {
    background: #b3e5fc;
    color: $color-front;
    .p-toast-message-icon {
      color: $color_16;
    }
    .p-toast-icon-close {
      color: $color-front;
    }
  }
  .p-toast-message.p-toast-message-success {
    background: #c8e6c9;
    color: $color-front;
    .p-toast-message-icon {
      color: $color_17;
    }
    .p-toast-icon-close {
      color: $color-front;
    }
  }
  .p-toast-message.p-toast-message-warn {
    background: #ffecb3;
    color: $color-front;
    .p-toast-message-icon {
      color: $color_18;
    }
    .p-toast-icon-close {
      color: $color-front;
    }
  }
  .p-toast-message.p-toast-message-error {
    background: #ffcdd2;
    color: $color-front;
    .p-toast-message-icon {
      color: $color_19;
    }
    .p-toast-icon-close {
      color: $color-front;
    }
  }
}
.p-galleria {
  .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: $color_20;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    .p-galleria-close-icon {
      font-size: 2rem;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: $color_20;
    }
  }
  .p-galleria-item-nav {
    background: transparent;
    color: $color_21;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    margin: 0 0.5rem;
    .p-galleria-item-prev-icon {
      font-size: 2rem;
    }
    .p-galleria-item-next-icon {
      font-size: 2rem;
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_20;
      }
    }
  }
  .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: $color_20;
    padding: 1rem;
  }
  .p-galleria-indicators {
    padding: 1rem;
    padding: 1rem;
    .p-galleria-indicator {
      button {
        background-color: $background-color_3;
        width: 1.25rem;
        height: 1.25rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

        &:hover {
          background: #ececec;
        }
      }
    }
    .p-galleria-indicator.p-highlight {
      button {
        background: rgba(63, 81, 181, 0.12);
        color: $color_4;
        background: #ff4081;
        color: $color_5;
      }
    }
  }
  .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
    .p-galleria-thumbnail-prev {
      margin: 0.5rem;
      background-color: $background-color_5;
      color: $color_20;
      width: 2rem;
      height: 2rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_20;
      }
    }
    .p-galleria-thumbnail-next {
      margin: 0.5rem;
      background-color: $background-color_5;
      color: $color_20;
      width: 2rem;
      height: 2rem;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_20;
      }
    }
    .p-galleria-thumbnail-item-content {
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
}
.p-galleria.p-galleria-indicators-bottom {
  .p-galleria-indicator {
    margin-right: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-top {
  .p-galleria-indicator {
    margin-right: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-left {
  .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-right {
  .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
}
.p-galleria.p-galleria-indicator-onitem {
  .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
    .p-galleria-indicator {
      button {
        background: rgba(255, 255, 255, 0.4);
        &:hover {
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .p-galleria-indicator.p-highlight {
      button {
        background: rgba(63, 81, 181, 0.12);
        color: $color_4;
        background: rgba(255, 64, 129, 0.68);
        color: $color_5;
      }
    }
  }
}
.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-preview-indicator {
  background-color: $background-color_5;
  color: $color_22;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-image-preview-container {
  &:hover {
    > .p-image-preview-indicator {
      background-color: $background-color_12;
    }
  }
}
.p-image-toolbar {
  padding: 1rem;
}
.p-image-action.p-link {
  color: $color_22;
  background-color: $background-color_5;
  width: 3rem;
  height: 3rem;

  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: $color_22;
    background-color: $background-color_13;
  }
  i {
    font-size: 1.5rem;
  }
}
.p-avatar {
  background-color: $background-color_14;

}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  .p-avatar-icon {
    font-size: 1.5rem;
  }
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  .p-avatar-icon {
    font-size: 2rem;
  }
}
.p-avatar-group {
  .p-avatar {
    border: 4px solid #ffffff;
  }
}
.p-badge {
  background: $color-primary;
  color: $color_5;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: $background-color_15;
  color: $color_5;
}
.p-badge.p-badge-success {
  background-color: $background-color_16;
  color: $color_5;
}
.p-badge.p-badge-info {
  background-color: $background-color_17;
  color: $color_5;
}
.p-badge.p-badge-warning {
  background-color: $background-color_18;
  color: $color_9;
}
.p-badge.p-badge-danger {
  background-color: $background-color_19;
  color: $color_5;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.p-chip {
  background-color: $background-color_14;
  color: var(--color-front);

  padding: 0 1rem;
  .p-chip-text {
    line-height: 1.5;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p-chip-icon {
    margin-right: 0.5rem;
  }
  .pi-chip-remove-icon {
    margin-left: 0.5rem;

    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  img {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: -1rem;
    margin-right: 0.5rem;
  }
}
.p-inplace {
  .p-inplace-display {
    padding: 1rem 1rem;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        color: var(--color-front);
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
}
.p-progressbar {
  height: 4px;
  background: rgba(63, 81, 181, 0.32);


  .p-progressbar-value {
    margin: 0;
    background: $color-primary;
  }
  .p-progressbar-label {
    color: $color_5;
    line-height: 4px;
    display: none !important;
  }
}
.p-scrolltop {
  width: 3rem;
  height: 3rem;

  border: 4px solid var(--color-front);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  .p-scrolltop-icon {
    font-size: 1.5rem;
    color: $color_5;
  }
}
.p-scrolltop.p-link {
  background: #ff4081;
  &:hover {
    background: rgba(255, 64, 129, 0.92);
  }
}
.p-skeleton {
  background-color: $background-color_20;

  &:after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    );
  }
}
.p-tag {
  background: $color-primary;
  color: $color_5;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;

  .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
}
.p-tag.p-tag-success {
  background-color: $background-color_16;
  color: $color_5;
}
.p-tag.p-tag-info {
  background-color: $background-color_17;
  color: $color_5;
}
.p-tag.p-tag-warning {
  background-color: $background-color_18;
  color: $color_9;
}
.p-tag.p-tag-danger {
  background-color: $background-color_19;
  color: $color_5;
}
.p-terminal {
  background: var(--color-back-lighter);
  color: var(--color-front);
  border: 1px solid #e0e0e0;
  padding: 1rem;
  .p-terminal-input {
    font-size: 1rem;
    font-family: $font-family_1;
  }
}
.p-calendar-w-btn {
  border: 1px solid rgba(0, 0, 0, 0.38);
  background: var(--color-back-lighter);

  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  .p-inputtext {
    background-image: none;
    background-color: $background-color_5;
    &:enabled {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .p-datepicker-trigger.p-button {
    background-color: $background-color_5;
    span {
      color: var(--color-front-lighter);
    }
    &:enabled {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
      &:active {
        background: rgba(0, 0, 0, 0.12);
      }
    }
    &:focus {
      background: rgba(0, 0, 0, 0.12);
    }
    &:disabled {
      background-color: $background-color_5 !important;
    }
  }
  &:not(.p-calendar-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-calendar-disabled).p-focus {
    border-color: $border-color_2;
    box-shadow: inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary,
      inset 0 0 0 1px $color-primary, inset 0 0 0 1px $color-primary;
  }
}
.p-calendar-w-btn.p-calendar-disabled {
  opacity: 0.38;
  .p-inputtext {
    opacity: 1;
  }
}
.p-cascadeselect-item {
  .p-ink {
    background-color: $background-color_22;
  }
}
.p-checkbox.p-checkbox-checked {
  &:not(.p-checkbox-disabled) {
    &:hover {
      box-shadow: 0 0 1px 10px rgba(63, 81, 181, 0.04);
    }
  }
  &:not(.p-checkbox-disabled).p-checkbox-focused {
    box-shadow: 0 0 1px 10px rgba(63, 81, 181, 0.12);
  }
}
.p-dropdown-item {
  .p-ink {
    background-color: $background-color_22;
  }
}
.p-inputswitch.p-inputswitch-checked.p-focus {
  .p-inputswitch-slider {
    &:before {
      box-shadow: 0 0 1px 10px rgba(63, 81, 181, 0.12),
        0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
  }
  &:not(.p-disabled) {
    &:hover {
      .p-inputswitch-slider {
        &:before {
          box-shadow: 0 0 1px 10px rgba(63, 81, 181, 0.12),
            0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}
.p-radiobutton.p-radiobutton-checked {
  &:not(.p-radiobutton-disabled) {
    &:hover {
      box-shadow: 0 0 1px 10px rgba(63, 81, 181, 0.04);
    }
  }
  &:not(.p-radiobutton-disabled).p-radiobutton-focused {
    box-shadow: 0 0 1px 10px rgba(63, 81, 181, 0.12);
  }
}
.p-slider.p-slider-sliding {
  .p-slider-handle {
    transform: scale(1);
  }
}
.p-treeselect-item {
  .p-ink {
    background-color: $background-color_22;
  }
}
.p-treeselect.p-invalid {
  &:not(.p-disabled).p-focus {
    border: 4px solid var(--color-front);
  }
}
.p-splitbutton.p-disabled.p-button-text {
  > .p-button {
    background-color: $background-color_5 !important;
    color: $color_23 var(--color-front-darker) !important;
  }
}
.p-splitbutton.p-disabled.p-button-outlined {
  > .p-button {
    background-color: $background-color_5 !important;
    color: $color_23 var(--color-front-darker) !important;
    border-color: $border-color_17 !important;
  }
}
@media screen and (max-width: 769px) {
  .p-datepicker {
    table {
      th {
        padding: 0;
      }
      td {
        padding: 0;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .p-dock.p-dock-top {
    .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
      .p-dock-list {
        margin: 0 auto;
      }
    }
  }
  .p-dock.p-dock-bottom {
    .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
      .p-dock-list {
        margin: 0 auto;
      }
    }
  }
  .p-dock.p-dock-left {
    .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
      .p-dock-list {
        margin: auto 0;
      }
    }
  }
  .p-dock.p-dock-right {
    .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
      .p-dock-list {
        margin: auto 0;
      }
    }
  }
  .p-dock {
    .p-dock-list {
      .p-dock-item {
        transform: none;
        margin: 0;
      }
    }
  }
  .p-menubar {
    position: relative;
    .p-menubar-button {
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
      color: var(--color-front-lighter);

      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:hover {
        color: var(--color-front-lighter);
        background: rgba(0, 0, 0, 0.04);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
    .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.5rem 0;
      background: var(--color-back-lighter);
      border: 4px solid var(--color-front);
      width: 100%;
      .p-menu-separator {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin: 0.5rem 0;
      }
      .p-submenu-icon {
        font-size: 0.875rem;
      }
      > .p-menuitem {
        width: 100%;
        position: static;
        > .p-menuitem-link {
          padding: 1rem 1rem;
          color: var(--color-front);

          transition: none;
          user-select: none;
          .p-menuitem-text {
            color: var(--color-front);
          }
          .p-menuitem-icon {
            color: var(--color-front-lighter);
            margin-right: 0.5rem;
          }
          .p-submenu-icon {
            color: var(--color-front-lighter);
          }
          &:not(.p-disabled) {
            &:hover {
              background: rgba(0, 0, 0, 0.04);
              .p-menuitem-text {
                color: var(--color-front);
              }
              .p-menuitem-icon {
                color: var(--color-front-lighter);
              }
              .p-submenu-icon {
                color: var(--color-front-lighter);
              }
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }
          > .p-submenu-icon {
            margin-left: auto;
            transition: transform 0.2s;
          }
        }
      }
      > .p-menuitem.p-menuitem-active {
        > .p-menuitem-link {
          > .p-submenu-icon {
            transform: rotate(-180deg);
          }
        }
      }
      .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        .p-submenu-icon {
          transition: transform 0.2s;
          transform: rotate(90deg);
        }
        .p-menuitem-active {
          > .p-menuitem-link {
            > .p-submenu-icon {
              transform: rotate(-90deg);
            }
          }
        }
      }
      .p-menuitem {
        width: 100%;
        position: static;
      }
      ul {
        li {
          a {
            padding-left: 3rem;
          }
          ul {
            li {
              a {
                padding-left: 5rem;
              }
              ul {
                li {
                  a {
                    padding-left: 7rem;
                  }
                  ul {
                    li {
                      a {
                        padding-left: 9rem;
                      }
                      ul {
                        li {
                          a {
                            padding-left: 11rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .p-menubar.p-menubar-mobile-active {
    .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
}
