/**
 * Global color palette
 * =================================
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */

// Generic colors

$blue: #288ad6;
$green: #16c98d;
$red: #fa5e5b;
$yellow: #feef6d;
$orange: #fe7c08;
$pink: #ff708e;
$purple: #8e44ad;
$gray: #95a5a6;

// Color Scheme

//Custom site vaiabes
$color-back: #f6f6f6;
$color-secondary-back: #e5e4dc;
$color-front: #292f36;
$color-secondary-front:#423b00;


// var(--color-front): #292f36;
// var(--color-front): #f6f6f6;
//$color-primary:#7400c0,;

$color-primary: $blue;
$color-secondary:$color-secondary-back;
$color-third: #F02E6E;
$color-fourth: #b8dbff;
$color-fifth: #ffcbd4;

$color-warning: #ffe0a4;
$color-error: #e76567;

$color-trait-hp: $green;
$color-trait-mp: $blue;
$color-trait-str: $purple;
$color-trait-def: $red;
$color-trait-spd: $pink;
$color-trait-luck: $yellow;

$color-link-default: $color-fourth;
$color-link-visited: $color-fourth;
$color-link-hover: darken($color-link-default, 20%);
$color-link-active: darken($color-link-default, 20%);

// Text Selection
$color-selection: #fff;
$color-selection-bg: $color-primary;

// <hr> border color
$hr-color: lighten(#000, 70%);

// 3rd party brand colors - find more at brandcolors.net
$color-twitter: #55acee;
$color-facebook: #3b5998;
$color-youtube: #cd201f;
$color-pinterest: #bd081c;
$color-instagram: #e4405f;
$color-whatsapp: #25d366;

//rarity palette
$color-rarity-common: $gray;
$color-rarity-uncommon: $green;
$color-rarity-rare: $blue;
$color-rarity-epic: $purple;
$color-rarity-legend: $orange;
