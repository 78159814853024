/**
 * Typography
 * =================================
 * - Base
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 * Body font size, leadings etc have been set in _variables.scss
 *
 * See http://modularscale.com for
 *
 */

html {
  font-size:1rem;
  line-height: 1rem;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: $font-family-base;
  color: var(--color-front);

  // Add ligatures
  font-feature-settings: "liga", "dlig", "hist";
  font-variant-ligatures: common-ligatures discretionary-ligatures
    historical-ligatures;
}

/**
 * Paragraphs
 */
p {
  font-family: $font-family-base;
  @include font-size($font-size-base);
  @include line-height($line-height-base);

  margin-top: 0;
  margin-bottom: $baseline;
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $font-family-headings;
  font-weight: $font-weight-headings;
  text-rendering: optimizelegibility; // Fix the character spacing for headings
  margin-top: 0;
  margin-bottom: $baseline;

  small {
    font-weight: normal;
  }
}

// Use the helper classes to emulate styles for another element
// For example: h3.h1 or h3.alpha
h1,
.h1,
.alpha {
  @include font-size($font-size-special);
  @include line-height($line-height-special);

  margin-bottom: $font-size-special * 2px;
}

h2,
.h2,
.beta {
  @include font-size($font-size-heading);
  @include line-height($line-height-heading);
  margin-bottom: $font-size-heading * 2px;
}

h3,
.h3,
.gamma {
  @include font-size($font-size-subheading);
  @include line-height($line-height-subheading);
}

h4,
.h4,
.delta {
  @include font-size($font-size-base);
  @include line-height($line-height-subheading);
}

h5,
.h5,
.epsilon,
h6,
.h6,
.zeta {
  @include font-size($font-size-base);
  @include line-height($line-height-base);

  margin-bottom: 0;
}

// Only give these headings a margin-top if they are after other elements
* + h1,
* + .h1,
* + .alpha,
* + h2,
* + .h2,
* + .beta,
* + h3,
* + .h3,
* + .gamma,
* + h4,
* + .h4,
* + .delta {
  margin-top: $baseline;
}

small {
  font-size: 80%;
}

/**
 * Miscellaneous
 */

// Emphasis
strong,
b {
  font-weight: $font-weight-bold;
}

em,
i {
  font-style: italic;
}

// Abbreviations and acronyms
abbr[title] {
  border-bottom: 1px dotted #ddd;
  cursor: help;
}

/**
 * Blockquotes
 */
blockquote {
  padding-left: 10px;
  margin: $baseline;
  border-left: 4px solid lighten(#000, 80%);

  p {
    margin-bottom: 0;
    @include font-size($font-size-base);
    font-weight: 300;
  }

  small {
    display: block;
    color: lighten(#000, 70%);

    &::before {
      content: "\2014 \00A0";
    }
  }
}

// Quotes
q,
blockquote {
  &::before,
  &::after {
    content: "";
  }
}

cite {
  font-style: normal;
}

// Addresses styling not present in S5, Chrome
dfn {
  font-style: italic;
}

// Addresses styling not present in IE6/7/8/9
mark {
  background: $yellow;
  padding: 2px 4px;
  border-radius: 3px;
}

// Prevents sub and sup affecting line-height in all browsers
// gist.github.com/413930
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
samp {
  font-family: $font-family-mono;
}

/**
 * Utilities
 */
.text-centre, // British or US English spellings..
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Horizontal rules
hr {
  margin: ($baseline) 0;
  border: 0;
  height: 1px;
  background-color: $hr-color;
}

// https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
