
@import "~primeicons/primeicons.css";
@import "~augmented-ui/augmented-ui.min.css";

/*
 * Mixins, helpers and functions
 */
// from https://github.com/modularscale/modularscale-sass
@import "include-media"; // From include-media.com
@import "color-palette";
@import "variables";
@import "mixins";

/**
  * Print styles
  */
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Montserrat+Alternates&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");
@import "~aos/dist/aos.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeflex/primeflex.css";
@import "theme";
@import "typography";
@import "global";
@import "onboard";
